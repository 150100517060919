import React, { useState } from 'react';
import {
  Box,
  Heading,
  Stack,
  Text,
  Flex,
  Button,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCurrentUser, setCredentials } from '../features/auth/authSlice';
import {
  useVerifyOtpMutation,
  useResendOtpMutation,
} from '../features/auth/authApiSlice';
import { useAppDispatch } from '../hooks';

import { OtpInput } from '../components/Verify/OtpInput';
import { PhoneNumberModal } from '../components/Verify/PhoneNumberModal';

export const Verify = () => {
  const [otp, setOtp] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [resendOtp, { isLoading: isResendOtpLoading, isSuccess }] =
    useResendOtpMutation();

  const onChange = (value: string) => {
    if (value.trim().length === 4) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setOtp(value);
  };

  if (!user) {
    return (
      <Box minWidth="340px" pt={4}>
        Loading
      </Box>
    );
  }
  const handleOnSubmitButtonClick = async () => {
    try {
      const { accessToken } = await verifyOtp({
        userGuid: user.guid,
        otp: otp,
      }).unwrap();

      if (accessToken) {
        dispatch(setCredentials({ token: accessToken }));
        navigate('/docs?type=BUSINESS_CONTRACTS');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        toast({
          title: t('verifyOtpPage.error.invalidOtp'),
          status: 'error',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const handleResendOtpClick = async () => {
    try {
      await resendOtp().unwrap();

      if (isSuccess) {
        toast({
          title: t('verifyOtpPage.otpSentSuccessfully'),
          status: 'success',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });
      }
    } catch (error: any) {
      if (error instanceof Error) {
        console.error(error.message);

        toast({
          title: t(`verifyOtpPage.error.${error.message}`),
          description: t(
            'verifyOtpPage.error.otpVerificationLimitExceededDescription',
          ),
          status: 'error',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });
      } else {
        toast({
          title: t(`verifyOtpPage.error.${error.data.message}`),
          description: t(
            'verifyOtpPage.error.otpVerificationLimitExceededDescription',
          ),
          status: 'error',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex minH={'80vh'} align={'center'} justify={'center'} px={5}>
      <Stack spacing={{ base: '8', md: '10' }} align="center">
        <Stack spacing={{ base: '4', md: '6' }} textAlign="center">
          <Stack spacing="3">
            <Heading size="lg" fontWeight="semibold">
              Введите одноразовый пароль
            </Heading>
          </Stack>
          <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
            Вам был отправлен одноразовый пароль на ваш зарегистрированный номер
            телефона. Пожалуйста, введите код ниже, чтобы завершить процесс
            регистрации.
          </Text>
        </Stack>

        <OtpInput value={otp} valueLength={4} onChange={onChange} />

        <Box minWidth="340px" pt={4}>
          <Button
            colorScheme="blue"
            variant="outline"
            w="100%"
            isDisabled={isButtonDisabled}
            onClick={handleOnSubmitButtonClick}
            isLoading={isLoading || isResendOtpLoading}
          >
            Подтвердить
          </Button>
        </Box>
        <Box textAlign="center">
          <Text color="gray.500" fontSize="md" maxW="3xl">
            Не получили смс с кодом?
            <Button
              colorScheme="telegram"
              variant="link"
              ml={2}
              onClick={handleResendOtpClick}
            >
              Отправить код заново
            </Button>
          </Text>

          <Text color="gray.500" fontSize="md" maxW="3xl" pt={2}>
            Указать заново мой номер
            <Button
              colorScheme="telegram"
              variant="link"
              ml={2}
              onClick={onOpen}
            >
              телефона
            </Button>
          </Text>
        </Box>
        <PhoneNumberModal isOpen={isOpen} onClose={onClose} />
      </Stack>
    </Flex>
  );
};
