import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Show,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { TEMPLATE_FIELD_TYPE } from '../../../../constants/doc-type';
import { useAppDispatch } from '../../../../hooks';
import {
  addAnswer,
  selectAnswers,
} from '../../../../features/user-document/userDocumentSlice';
import { useSelector } from 'react-redux';
import { CheckboxField } from './CheckboxField';

export interface IDocumentField {
  name: string;
  documentName: string;
  type: string;
  tip?: string;
  fieldValues: Record<string, string | number>[];
  disableButton: (value: boolean) => void;
}

export const DocumentField = ({
  name,
  type,
  tip,
  documentName,
  fieldValues,
  disableButton,
}: IDocumentField) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [isInputInvalid, setIsInputInvalid] = useState(false);

  const answers = useSelector(selectAnswers);
  const dispatch = useAppDispatch();

  const handleChange = useCallback((value: string) => {
    dispatch(addAnswer({ [name]: value }));
    disableButton(false);
  }, []);

  useEffect(() => disableButton(Boolean(!answers[name])), [name]);

  const onAlertClose = () => {
    setIsVisible(false);
  };

  const numberInputChange = useCallback((value: string) => {
    if (!value) {
      disableButton(true);
      dispatch(addAnswer({ [name]: value }));
      setIsInputInvalid(true);
      return;
    }

    dispatch(addAnswer({ [name]: value }));
    setIsInputInvalid(false);
    disableButton(false);
  }, []);

  if (type === TEMPLATE_FIELD_TYPE.RADIO) {
    return (
      <VStack
        spacing={4}
        align="flex-start"
        maxWidth={{
          base: '320px',
          sm: '400px',
          md: '450px',
          lg: '550px',
          xl: '600px',
        }}
      >
        <Show breakpoint="(min-width: 429px)">
          {tip && isVisible && (
            <Box>
              <Alert status="info" variant="left-accent" borderRadius={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>{t('common.attention')}</AlertTitle>
                  <AlertDescription>
                    {t(`${documentName}.${tip}`)}
                  </AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={onAlertClose}
                />
              </Alert>
            </Box>
          )}
        </Show>
        <RadioGroup
          onChange={handleChange}
          value={answers[name] ? answers[name].toString() : undefined}
          size={'lg'}
        >
          <Stack>
            {fieldValues.map((fv) => (
              <Radio key={fv.value} value={fv.value.toString()}>
                <Text fontSize={{ base: 'md', lg: 'lg' }}>
                  {t(`${documentName}.${fv.value}`)}
                </Text>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>

        <Show breakpoint="(max-width: 428px)">
          {tip && isVisible && (
            <Box>
              <Alert status="info" variant="left-accent" borderRadius={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>{t('common.attention')}</AlertTitle>
                  <AlertDescription>
                    {t(`${documentName}.${tip}`)}
                  </AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={onAlertClose}
                />
              </Alert>
            </Box>
          )}
        </Show>
      </VStack>
    );
  }

  if (type === TEMPLATE_FIELD_TYPE.NUMBER_INPUT) {
    return (
      <VStack
        spacing={4}
        align="flex-start"
        maxWidth={{
          base: '320px',
          sm: '400px',
          md: '450px',
          lg: '550px',
          xl: '600px',
        }}
      >
        <Show breakpoint="(min-width: 429px)">
          {tip && isVisible && (
            <Box>
              <Alert status="info" variant="left-accent" borderRadius={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>{t('common.attention')}</AlertTitle>
                  <AlertDescription>
                    {t(`${documentName}.${tip}`)}
                  </AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={onAlertClose}
                />
              </Alert>
            </Box>
          )}
        </Show>

        <Box minWidth={'100%'}>
          <NumberInput
            value={answers[name] as number}
            onChange={numberInputChange}
            isInvalid={isInputInvalid}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>

        <Show breakpoint="(max-width: 428px)">
          {tip && isVisible && (
            <Box>
              <Alert status="info" variant="left-accent" borderRadius={4}>
                <AlertIcon />
                <Box>
                  <AlertTitle>{t('common.attention')}</AlertTitle>
                  <AlertDescription>
                    {t(`${documentName}.${tip}`)}
                  </AlertDescription>
                </Box>
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                  onClick={onAlertClose}
                />
              </Alert>
            </Box>
          )}
        </Show>
      </VStack>
    );
  }

  if (type === TEMPLATE_FIELD_TYPE.CHECKBOX) {
    return (
      <CheckboxField
        name={name}
        documentName={documentName}
        tip={tip}
        fieldValues={fieldValues}
        value={answers[name] as string[]}
        disableButton={disableButton}
      />
    );
  }

  return (
    <>
      <Text>Undefined Field</Text>
    </>
  );
};
