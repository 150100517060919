import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, Hide, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const SearchFilter = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = () => {
    navigate(`/docs?search=${searchQuery}`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
  };

  return (
    <Flex justifyContent="center" alignItems="center" gap="2">
      <Box width="100%">
        <Input
          size="lg"
          placeholder={t('homePage.searchDocumentsPlaceholder')}
          borderRadius="12px"
          onChange={handleInputChange}
        />
      </Box>
      <Hide below="md">
        <Box>
          <Button
            size="lg"
            colorScheme="blue"
            width="159px"
            borderRadius="14px"
            onClick={handleSubmit}
          >
            {t('common.find')}
          </Button>
        </Box>
      </Hide>
    </Flex>
  );
};
