import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Show,
  Text,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

import { useInitPaymentMutation } from '../features/user-document/userDocumentApiSlice';
import { RadioCard } from '../components/common/RadioCard/RadioCard';
import { IPaymentPlan, paymentPlans } from '../constants/pricing';

export const Checkout = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [paymentPlan, setPaymentPlan] = useState('');
  const [initPayment, { isLoading }] = useInitPaymentMutation();
  const { docId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (value: string) => {
    setPaymentPlan(value);
    setIsDisabled(false);
  };

  const handlePayment = useCallback(async () => {
    const plan = paymentPlans.find((p: IPaymentPlan) => p.code === paymentPlan);
    if (!plan || !docId) return;

    try {
      const paymentInfo = await initPayment({
        userDocumentGuid: docId,
      }).unwrap();

      if (paymentInfo?.checkoutUrl) {
        window.location.replace(paymentInfo.checkoutUrl);
      }
    } catch (e) {
      return;
    }
  }, [paymentPlan]);

  const handleBack = () => {
    navigate(-1);
  };

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: '',
    onChange: handleChange,
  });

  return (
    <>
      <Container maxW={'7xl'} minH={'90vh'}>
        <Center>
          <VStack
            spacing={4}
            align="flex-start"
            pt={{ base: '20px', lg: '50px' }}
            px={{ base: '10px', lg: '20px' }}
            {...getRootProps()}
          >
            <Box pb="20px">
              <Text fontSize="2xl" fontWeight="bold">
                {t('checkOutPage.header')}
              </Text>
            </Box>

            {paymentPlans.map((plan) => {
              return (
                <RadioCard
                  key={plan.name}
                  rate={plan}
                  {...getRadioProps({ value: plan.code })}
                />
              );
            })}
            <Show breakpoint="(min-width: 429px)">
              <Box pt="24px" width="100%">
                <Button
                  width="100%"
                  p={5}
                  colorScheme={isDisabled ? 'gray' : 'blue'}
                  isDisabled={isDisabled}
                  onClick={handlePayment}
                  isLoading={isLoading}
                >
                  {t('checkOutPage.confirmPayment')}
                </Button>
              </Box>
            </Show>
          </VStack>
        </Center>
      </Container>
      <Show breakpoint="(max-width: 428px)">
        <Box
          position="sticky"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          p={6}
          borderRadius={'16px 16px 0px 0px'}
          boxShadow={'0px 0px 15px rgba(92, 96, 104, 0.3);'}
        >
          <Flex gap={3}>
            <Button
              colorScheme="gray"
              width="100%"
              borderRadius="10px"
              onClick={handleBack}
              h={'50px'}
            >
              {t('common.back')}
            </Button>

            <Button
              colorScheme={isDisabled ? 'gray' : 'blue'}
              width="100%"
              borderRadius="10px"
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={handlePayment}
              h={'50px'}
            >
              {t('checkOutPage.confirmPayment')}
            </Button>
          </Flex>
        </Box>
      </Show>
    </>
  );
};
