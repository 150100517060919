import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  Text,
  ListItem,
  UnorderedList,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';

export const PaymentTerms = () => {
  const generateDate = () => {
    const date = new Date();
    return `По состоянию на ${format(date, 'dd.MM.yyyy')}`;
  };

  return (
    <>
      <Container maxW={'7xl'} minH={'90vh'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          py={6}
          px={4}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={'/'}>
              Главная
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true}>
            <BreadcrumbLink
              style={{ cursor: 'default', textDecoration: 'none' }}
            >
              Условия оплаты
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box pt={'4'}>
          <Card p={'20px'}>
            <CardHeader>
              <Heading size="md">УСЛОВИЯ ОПЛАТЫ И ВОЗВРАТА </Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={6}>
                <Box>
                  <Heading
                    as="h4"
                    fontSize="14px"
                    textAlign="right"
                    width="100%"
                  >
                    {generateDate()}
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm" textAlign="center">
                      После нажатия кнопки «Оплатить» Вы перейдете на
                      специальную защищенную платежную страницу сервиса{' '}
                      <a
                        href="https://freedompay.money/kg"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: '#4299e1' }}
                      >
                        Freedom Pay (PayBox)
                      </a>{' '}
                      . На платежной странице будет указан номер заказа и сумма
                      платежа. Для оплаты Вам необходимо ввести свои карточные
                      данные и подтвердить платеж, нажав на кнопку «Оплатить».
                      Если Ваша карта поддерживает технологию 3-D Secure, Вам
                      будет предложено пройти стандартную одноминутную процедуру
                      проверки владельца карты на сайте Вашего банка.
                    </Text>

                    <Text fontSize="sm" pt={4}>
                      Обращаем Ваше внимание, что после проведения платежа на
                      Ваш электронный адрес может прийти подтверждение оплаты.
                      Просим Вас сохранять данные оплат.
                    </Text>

                    <Text fontSize="sm" textAlign="left" pt={4}>
                      Мы принимаем платежи по следующим банковским картам: Visa,
                      Visa Electron, MasterCard, Maestro, Элкарт
                    </Text>
                  </Box>
                </Box>

                <Box>
                  <Heading as="h4" size="sm">
                    Возврат
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      Согласно Закону Кыргызской Республики «О защите прав
                      потребителей» (ч. 1 ст. 37) Если исполнитель
                      (Администрация сайта) несвоевременно оказала услугу или
                      если во время оказания услуг, Пользователю (потребителю)
                      стало очевидным, что оказание услуги не будет осуществлено
                      в срок, а также в случае просрочки оказания услуги
                      потребитель вправе по своему выбору:
                    </Text>
                    <UnorderedList fontSize="sm" pl={4}>
                      <ListItem>
                        назначить исполнителю новый срок, в течение которого
                        исполнитель должен приступить к оказанию услуги и (или)
                        закончить оказание услуги, и потребовать уменьшения цены
                        оказание услуги;
                      </ListItem>
                      <ListItem>
                        поручить оказание услуги третьим лицам за разумную цену
                        или выполнить ее своими силами и потребовать от
                        исполнителя возмещения понесенных расходов{' '}
                        <b>
                          на сумму не превышающую стоимость услуг исполнителя;
                        </b>
                      </ListItem>
                      <ListItem>
                        потребовать уменьшения цены за оказание услуги;
                      </ListItem>
                      <ListItem>
                        расторгнуть договор и прекратить использование Сайта.
                      </ListItem>
                    </UnorderedList>
                    <Text fontSize="sm">
                      Потребитель вправе потребовать также полного возмещения
                      убытков, причиненных ему в связи с нарушением сроков
                      начала и (или) окончания оказания услуги. Убытки
                      возмещаются в сроки, установленные для удовлетворения
                      соответствующих требований потребителя.
                    </Text>
                    <Text fontSize="sm">
                      Сумма требуемой потребителем неустойки (пени){' '}
                      <b>
                        не может превышать цену оказания услуги или общую цену
                        заказа.
                      </b>
                    </Text>
                    <Text fontSize="sm">
                      Рассмотрение вопроса о возврате уплаченных денежных
                      средств может рассматриваться не более 180 дней со дня
                      уведомления Пользователя. При возврате денежных средств
                      Пользователю подлежит удержанию комиссия платежного
                      сервиса Freedom Pay (PayBox) и единый налог в размере 4%.
                    </Text>
                  </Box>

                  <Box pt={4}>
                    <Heading as="h4" size="sm">
                      Отмена заказа
                    </Heading>
                    <Box pt={4}>
                      <Text fontSize="sm">
                        При аннулировании позиций из оплаченного заказа (или при
                        аннулировании заказа целиком) по уважительным причинам,
                        не связанными с виной Пользователя (потребителя), Вы
                        можете заказать другие услуги на эту сумму, либо вернуть
                        всю сумму на карту, предварительно написав письмо на
                        электронную почту.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      </Container>
    </>
  );
};
