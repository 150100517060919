import jwtDecode from 'jwt-decode';
import { UserDto } from '../dto/user.dto';

export const setAuthToken = (token: string) => {
  localStorage.setItem('accessToken', token);
};

export const getAuthToken = () => {
  return localStorage.getItem('accessToken');
};

export const clearLocalStorage = () => {
  localStorage.removeItem('accessToken');
};

export const getCurrentUserByAuthToken = () => {
  const token = getAuthToken();
  if (token) return jwtDecode(token) as UserDto;

  return null;
};
