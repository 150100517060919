import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from '../../dto/user.dto';
import { RootState } from '../../store';
import {
  clearLocalStorage,
  getAuthToken,
  getCurrentUserByAuthToken,
  setAuthToken,
} from '../../utils/auth';

export interface IAuthState {
  user: UserDto | null;
  token: string | null;
}

const initialState: IAuthState = {
  user: getCurrentUserByAuthToken(),
  token: getAuthToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>,
    ) => {
      clearLocalStorage();
      setAuthToken(token);

      state.user = getCurrentUserByAuthToken();
      state.token = token;
    },
    resetAuthState: (state) => {
      clearLocalStorage();

      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, resetAuthState } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
