import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { useAppSelector } from '../../../hooks';

import { UserRoles } from '../../../constants/user';
import { selectCurrentUser } from '../../../features/auth/authSlice';
import { Navbar } from '../Navbar';

interface IPrivateRoutesProps {
  allowedRoles: UserRoles[];
}

export const PrivateRoutes = ({ allowedRoles }: IPrivateRoutesProps) => {
  const user = useAppSelector(selectCurrentUser);
  const location = useLocation();
  const isAuthorized =
    user && (allowedRoles.includes(user.role) || user.isVerified);

  return isAuthorized ? (
    <Box>
      <Navbar />
      <Outlet />
    </Box>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
