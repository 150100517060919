import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { ThreeCircles } from 'react-loader-spinner';

export const Fallback = () => (
  <Flex
    minH={'100vh'}
    align={'center'}
    justify={'center'}
    bg={useColorModeValue('gray.50', 'gray.800')}
  >
    <ThreeCircles
      height="120"
      width="120"
      color="#3778DB"
      visible={true}
      ariaLabel="three-circles-rotating"
    />
  </Flex>
);
