import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Container,
  Flex,
  Icon,
  Show,
  Spacer,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useSearchParams } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { HiOutlineDocumentText } from 'react-icons/hi2';

import { useFetchTemplatesQuery } from '../../features/templates/templateApiSlice';

import { NavButton } from '../../components/common/NavButton/NavButton';
import { SearchFilter } from '../../components/Documents/SearchFilter';
import { DocumentsTable } from '../../components/Table/DocumentsTable';

export const Documents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState({ search: '', type: '' });
  const [type, setType] = useState('EMPLOYMENT_CONTRACTS');
  const { data: documents, isLoading } = useFetchTemplatesQuery(searchQuery);
  const { t } = useTranslation();

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    const search = currentParams.search || '';
    const type = currentParams.type || '';

    setSearchParams(currentParams);
    setSearchQuery({ search, type });
  }, [searchParams]);

  const handleOnEmploymentClick = () => {
    setSearchParams({ type: 'EMPLOYMENT_CONTRACTS' });
    setType('EMPLOYMENT_CONTRACTS');
  };
  const handleOnBusinessClick = () => {
    setSearchParams({ type: 'BUSINESS_CONTRACTS' });
    setType('BUSINESS_CONTRACTS');
  };
  const handleOnCourtClick = () => {
    setSearchParams({ type: 'COURT_DOCUMENTS' });
    setType('COURT_DOCUMENTS');
  };

  const handleOnPolicyClick = () => {
    setSearchParams({ type: 'POLICY_DOCUMENTS' });
    setType('POLICY_DOCUMENTS');
  };

  if (!documents || isLoading) {
    return (
      <Center minHeight="600px">
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  return (
    <>
      <Flex as="section" minH="100vh" bg="bg-canvas">
        <Show breakpoint="(min-width: 769px)">
          <Flex
            flex="1"
            bg="bg-surface"
            maxW={{ base: 'full', sm: 'xs' }}
            py={{ base: '6', sm: '8' }}
            px={{ base: '4', sm: '6' }}
            borderRight={1}
            borderStyle={'solid'}
            borderColor={'gray.200'}
          >
            <Stack justify="space-between" spacing="1" width="full">
              <Stack spacing="8" shouldWrapChildren>
                <Stack spacing="1">
                  <NavButton
                    label={t('userDocumentsPage.sideBar.home')}
                    icon={FiHome}
                  />
                </Stack>
                <Stack>
                  <Text fontSize="sm" color="subtle" fontWeight="medium">
                    {t('userDocumentsPage.sideBar.documents')}
                  </Text>
                  <Stack spacing="1">
                    <NavButton
                      label={t('documentTypes.EMPLOYMENT_CONTRACTS')}
                      icon={HiOutlineDocumentText}
                      isActive={type === 'EMPLOYMENT_CONTRACTS'}
                      onClick={handleOnEmploymentClick}
                    />
                    <NavButton
                      label={t('documentTypes.BUSINESS_CONTRACTS')}
                      icon={HiOutlineDocumentText}
                      isActive={type === 'BUSINESS_CONTRACTS'}
                      onClick={handleOnBusinessClick}
                    />
                    <NavButton
                      label={t('documentTypes.COURT_DOCUMENTS')}
                      icon={HiOutlineDocumentText}
                      isActive={type === 'COURT_DOCUMENTS'}
                      onClick={handleOnCourtClick}
                    />

                    <NavButton
                      label={t('documentTypes.POLICY_DOCUMENTS')}
                      icon={HiOutlineDocumentText}
                      isActive={type === 'POLICY_DOCUMENTS'}
                      onClick={handleOnPolicyClick}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Flex>
        </Show>
        <Flex flex="1" bg={'gray.50'}>
          <Container maxW={'8xl'} my={14}>
            <Box bg="white" py={4} borderRadius="lg">
              <Flex px={6}>
                <Show breakpoint="(min-width: 600px)">
                  <Box>
                    <Text
                      fontWeight="semibold"
                      fontSize={{ base: 'md', lg: 'lg' }}
                    >
                      {t('documentsPage.pageHeader')}
                    </Text>
                  </Box>
                </Show>
                <Spacer />
                <Box minWidth={{ base: '300px', lg: 'lg' }}>
                  <SearchFilter />
                </Box>
              </Flex>
              <DocumentsTable documents={documents} />
            </Box>
          </Container>
        </Flex>
      </Flex>

      <Show breakpoint="(max-width: 769px)">
        <Box
          position="sticky"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          pt={4}
          pb={6}
          boxShadow={'0px 0px 15px rgba(92, 96, 104, 0.3);'}
        >
          <Flex justifyContent={'space-around'}>
            <Box
              color={type === 'EMPLOYMENT_CONTRACTS' ? 'blue.500' : 'gray.500'}
              textAlign={'center'}
              w={'110px'}
              onClick={handleOnEmploymentClick}
            >
              <Icon as={HiOutlineDocumentText} fontSize={'1.3em'} />
              <Text fontSize={'0.9em'} lineHeight={0.9}>
                {t('documentTypes.EMPLOYMENT_CONTRACTS')}
              </Text>
            </Box>

            <Box
              color={type === 'BUSINESS_CONTRACTS' ? 'blue.500' : 'gray.500'}
              textAlign={'center'}
              w={'110px'}
              onClick={handleOnBusinessClick}
            >
              <Icon as={HiOutlineDocumentText} fontSize={'1.3em'} />
              <Text fontSize={'0.9em'} lineHeight={0.9}>
                {t('documentTypes.BUSINESS_CONTRACTS')}
              </Text>
            </Box>
            <Box
              color={type === 'COURT_DOCUMENTS' ? 'blue.500' : 'gray.500'}
              textAlign={'center'}
              w={'110px'}
              onClick={handleOnCourtClick}
            >
              <Icon as={HiOutlineDocumentText} fontSize={'1.3em'} />
              <Text fontSize={'0.9em'} lineHeight={0.9}>
                {t('documentTypes.COURT_DOCUMENTS')}
              </Text>
            </Box>

            <Box
              color={type === 'POLICY_DOCUMENTS' ? 'blue.500' : 'gray.500'}
              textAlign={'center'}
              w={'110px'}
              onClick={handleOnPolicyClick}
            >
              <Icon as={HiOutlineDocumentText} fontSize={'1.3em'} />
              <Text fontSize={'0.9em'} lineHeight={0.9}>
                {t('documentTypes.POLICY_DOCUMENTS')}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Show>
    </>
  );
};
