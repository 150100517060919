import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Show, Stack, Text, VStack } from '@chakra-ui/react';

import { IDocumentProperty } from '../../../dto/document';
import { DocumentField } from './Fields/Fields';
import { useAppDispatch } from '../../../hooks';
import { setIsInProgress } from '../../../features/user-document/userDocumentSlice';

export interface IDocumentFields {
  documentName: string;
  fields: IDocumentProperty[];
  currentStep: number;
  previousStep: () => void;
  nextStep: (currentState: number) => void;
}

export const FieldsWithNavigation = ({
  documentName,
  fields,
  currentStep,
  previousStep,
  nextStep,
}: IDocumentFields) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep === fields.length - 1) {
      dispatch(setIsInProgress(false));
      return;
    }

    setIsDisabled(true);
    return nextStep(currentStep);
  };

  const handlePrevious = () => {
    if (currentStep !== 0) {
      return previousStep();
    } else {
      return navigate(-1);
    }
  };

  const handleDisableButton = (value: boolean) => {
    setIsDisabled(value);
  };

  return (
    <Box width="100%">
      {fields.map((field, index: number) => {
        const isVisible = index === currentStep;

        return (
          isVisible && (
            <Box key={field.name} px={2}>
              <Text
                fontSize={{ base: 'lg', lg: '2xl', md: '2xl' }}
                fontWeight={600}
                py={8}
              >
                {t(`${documentName}.${field.name}`)}
              </Text>
              <DocumentField
                {...field}
                documentName={documentName}
                disableButton={handleDisableButton}
              />

              <Show breakpoint="(min-width: 600px)">
                <Stack direction={'row'} spacing="12px" py={12}>
                  <Button
                    colorScheme="gray"
                    isDisabled={false}
                    borderRadius={'10px'}
                    py={'24px'}
                    width={'182px'}
                    fontSize={'17px'}
                    onClick={handlePrevious}
                  >
                    {t('common.back')}
                  </Button>

                  <Button
                    colorScheme="blue"
                    isDisabled={isDisabled}
                    borderRadius={'10px'}
                    py={'24px'}
                    fontSize={'17px'}
                    width={'182px'}
                    onClick={handleNext}
                  >
                    {t('common.continue')}
                  </Button>
                </Stack>
              </Show>
              <Show breakpoint="(max-width: 600px)">
                <Box pt={'4em'}>
                  <VStack spacing={3}>
                    <Button
                      colorScheme="blue"
                      width="100%"
                      borderRadius="10px"
                      isDisabled={isDisabled}
                      onClick={handleNext}
                    >
                      {t('common.continue')}
                    </Button>

                    <Button
                      colorScheme="gray"
                      width="100%"
                      borderRadius="10px"
                      isDisabled={false}
                      onClick={handlePrevious}
                    >
                      {t('common.back')}
                    </Button>
                  </VStack>
                </Box>
              </Show>
            </Box>
          )
        );
      })}
    </Box>
  );
};
