import { apiSlice } from '../../api/apiSlice';
import {
  ITemplateListDto,
  ITemplateTypeListDto,
  QueryTemplatesDto,
} from '../../dto/template.dto';
import { IDocumentDto } from '../../dto/document';

export const templatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTemplates: builder.query<ITemplateListDto, QueryTemplatesDto | void>({
      query: (dto: QueryTemplatesDto) => ({
        url: '/templates',
        params: dto,
      }),
      providesTags: ['Templates'],
    }),
    fetchTemplateTypes: builder.query<ITemplateTypeListDto, string | undefined>(
      {
        query: (q) => `/templates/document/types?search=${q}`,
        keepUnusedDataFor: 28800, //8h
      },
    ),
    fetchTemplate: builder.query<IDocumentDto, string | undefined>({
      query: (id) => `/templates/${id}`,
    }),
  }),
});

export const {
  useFetchTemplatesQuery,
  useFetchTemplateTypesQuery,
  useFetchTemplateQuery,
} = templatesApiSlice;
