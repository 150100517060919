export enum DocType {
  EMPLOYMENT_CONTRACTS = 'EMPLOYMENT_CONTRACTS',
  BUSINESS_CONTRACTS = 'BUSINESS_CONTRACTS',
  COURT_DOCUMENTS = 'COURT_DOCUMENTS',
}

export enum TEMPLATE_FIELD_TYPE {
  RADIO = 'radio',
  NUMBER_INPUT = 'number',
  INPUT = 'input',
  CHECKBOX = 'checkbox',
}
