export enum UserRole {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
}

export interface UserDto {
  guid: string;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  role: UserRole;

  isVerified: boolean;
}

export interface SignUpDto {
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface SignInDto {
  email: string;
  password: string;
}

export interface ForgotPasswordDto {
  email: string;
}

export interface ResetPasswordDto {
  password: string;
  passwordConfirmation: string;
}
