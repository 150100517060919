import {
  HStack,
  Show,
  Table,
  TableProps,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as React from 'react';
import { ITemplateListDto } from '../../dto/template.dto';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IDocumentsTable extends TableProps {
  documents: ITemplateListDto;
}

export const DocumentsTable = (props: IDocumentsTable) => {
  const documents = props.documents.items;
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Thead>
        <Tr borderTop="1px" borderTopColor="gray.100" bgColor="gray.100">
          <Show breakpoint="(min-width: 600px)">
            <Th>#</Th>
          </Show>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text>{t('documentsTable.name')}</Text>
              </HStack>
            </HStack>
          </Th>

          <Th>{t('documentsTable.price')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {documents.length ? (
          documents.map((document, index) => (
            <Tr key={document.guid}>
              <Show breakpoint="(min-width: 600px)">
                <Td>{index + 1}</Td>
              </Show>
              <Td>
                <Link to={`/docs/${document.guid}`} key={document.guid}>
                  <Text fontWeight="medium">{t(`${document.name}.name`)}</Text>
                </Link>
              </Td>

              <Td minW={'124px'}>
                <Tag
                  size="sm"
                  borderRadius="full"
                  variant="outline"
                  colorScheme={Number(document.price) === 0 ? 'green' : 'blue'}
                  backgroundColor={
                    Number(document.price) === 0 ? 'green.50' : 'blue.50'
                  }
                >
                  {Number(document.price) === 0
                    ? 'Бесплатно'
                    : ` ${document.price / 100} Сом`}
                </Tag>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={3}>
              <Text textAlign="center" color="gray.500">
                По вашему запросу ничего не найдено
              </Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
