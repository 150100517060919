import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ChevronRightIcon } from '@chakra-ui/icons';

export const UserAgreement = () => {
  return (
    <>
      <Container maxW={'7xl'} minH={'90vh'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          py={6}
          px={4}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={'/'}>
              Главная
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true}>
            <BreadcrumbLink
              style={{ cursor: 'default', textDecoration: 'none' }}
            >
              Пользовательское соглашение
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box pt={'4'}>
          <Card p={'20px'}>
            <CardHeader>
              <Heading size="md">
                ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ (ПУБЛИЧНАЯ ОФЕРТА)
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={6}>
                <Box>
                  <Heading as="h4" size="sm">
                    1. ОБЩИЕ ПОЛОЖЕНИЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      1.1. В соответствии со статьей 396 Гражданского кодекса
                      Кыргызской Республики настоящее пользовательское
                      соглашение (далее по тексту Соглашение) является
                      письменной публичной офертой и содержит правила, условия
                      предоставления и использования услуг сайта{' '}
                      <Link to={'/'} style={{ color: '#4299e1' }}>
                        https://legaldocs.kg/
                      </Link>{' '}
                      (далее по тексту Сайт) для обмена информацией об имеющихся
                      на Сайте услугах. После одобрения Клиентом настоящего
                      Соглашения (акцепта) в установленном порядке, Соглашение
                      принимает силу договора и подлежит соблюдению Сторонами.
                    </Text>
                    <Text fontSize="sm">
                      1.2. Настоящее Соглашение и другие подобные правила и
                      политики Сайта в дальнейшем совместно именуются «Условия».
                    </Text>
                    <Text fontSize="sm">
                      1.3. Каждый Клиент обязан ознакомиться с Условиями Сайта
                      до момента его использования и оформления заказа.
                      Использование Сайта означает полное и безоговорочное
                      принятие и соблюдение Клиентом Условий Сайта.
                    </Text>
                    <Text fontSize="sm">
                      1.4. Сайт вправе в любое время в одностороннем порядке
                      вносить изменения в настоящее Соглашение без получения
                      согласия и без специального письменного и/или устного
                      уведомления Клиента. Новая редакция Соглашения вступает в
                      силу <Text as="b">c момента ее размещения на Сайте</Text>,
                      если новой редакцией не предусмотрено иное. В связи с
                      этим, Клиент обязуется регулярно отслеживать изменения.
                    </Text>
                    <Text fontSize="sm">
                      1.5. В случае несогласия Клиента с какими-либо положениями
                      настоящего Соглашения Клиент вправе не использовать Сайт и
                      не пользоваться его услугами по составлению правовых
                      документов. В случае несогласия с какими-либо изменениями,
                      внесенными Администрацией Сайта в настоящее Соглашение,
                      Клиент обязан прекратить использование Сайта.
                      Использование Сайта после внесения Администрацией Сайта
                      изменений в Соглашение в порядке, описанном в пункте 1.4
                      настоящего Соглашения, означает полное принятие Клиентом
                      условий Соглашения (акцепта).
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      2.1. Сайт – это комплекс программных и аппаратных средств,
                      который предоставляет возможность информационного и
                      технологического взаимодействия между всеми участниками
                      процесса, совершаемый с использованием сети интернет
                      действий, направленных на предоставление Администрацией
                      сайта услуг Клиенту на сайте{' '}
                      <Link to={'/'} style={{ color: '#4299e1' }}>
                        https://legaldocs.kg/
                      </Link>
                      .
                    </Text>
                    <Text fontSize="sm">
                      2.2. Администрация Сайта – Общество с ограниченной
                      ответственностью «В рамках закона», создавшее Сайт,
                      осуществляющее администрирование и техническую поддержку
                      его работы, и предоставляющее Клиентам услуги по
                      составлению правовых документов посредством данного Сайта
                      и сторонних Сервисов.
                    </Text>
                    <Text fontSize="sm">
                      2.3. Клиент – физические и/или юридические лица, принявшее
                      условия Пользовательского соглашения и иных правил и
                      политик ОсОО «В рамках закона».
                    </Text>
                    <Text fontSize="sm">
                      2.4. Стороны - Администрация сайта и Клиент. Настоящий
                      раздел не является исчерпывающим для толкования терминов,
                      применяемых в настоящем Соглашении. Содержание отдельных
                      терминов может быть приведено в других разделах данного
                      Соглашения или непосредственно на самом Сайте.
                    </Text>
                    <Text fontSize="sm">
                      2.5. Термины, которые указаны в анкете (опросном листе)
                      каждого проекта правового документа, указаны в строгом
                      соответствии с действующим законодательством Кыргызской
                      Республики и не могут толковаться иначе.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    3. ПРЕДМЕТ СОГЛАШЕНИЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      3.1. Сайт предоставляет Клиенту информацию об услугах
                      Сайта по составлению правовых документов, а также
                      рекламную информацию о консультационных услугах и иных
                      услугах, оказываемых ОсОО «В рамках закона»
                      неограниченному кругу лиц.
                    </Text>
                    <Text fontSize="sm">
                      рамках закона» неограниченному кругу лиц. 3.2. Клиент дает
                      свое согласие на предоставление Администрации сайта
                      персональных данных, в объеме необходимом для исполнения
                      Администрацией сайта своих обязательств перед Клиентом.
                      Детальный объем передаваемых персональных данных указан в
                      Политике Конфиденциальности и иных уведомлениях и
                      сообщениях, размещенных на Сайте.
                    </Text>
                    <Text fontSize="sm">
                      3.3. Клиент в момент использования Сайта, в том числе
                      оформления заказа услуг или получения услуг, выражает свое
                      согласие на доступ третьих лиц к своим персональным данным
                      в случаях, предусмотренных законодательством Кыргызской
                      Республики. Такими случаями могут быть, к примеру,
                      нарушение Клиентом действующего законодательства
                      Кыргызской Республики и поступления запроса от
                      правоохранительных органов относительно совершенного или
                      причастности Клиента к какому-либо преступлению
                      уголовно-наказуемого характера. Все персональные данные,
                      указанные Клиентом на Сайте, охраняются согласно
                      действующему законодательству Кыргызской Республики о
                      защите персональных данных.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    4. ПОРЯДОК ОКАЗАНИЯ УСЛУГ. СПОСОБ ОПЛАТЫ.
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      4.1. Для получения услуг на Сайте, Клиенту необходимо
                      пройти регистрацию на Сайте путем предоставления
                      запрашиваемой информации, включая адрес электронной почты
                      Клиента, на которое поступит уведомление от с указанием
                      присвоенного уникального номера «личного кабинета
                      Клиента».
                    </Text>
                    <Text fontSize="sm">
                      4.2. Оформление заявки на получение услуг осуществляется
                      путем заполнения Клиентом электронной формы анкеты
                      (опросного листа) к каждому проекту правового документа,
                      опубликованного на Сайте.
                    </Text>
                    <Text fontSize="sm">
                      4.3. После оформления анкеты (опросного листа) и выбора
                      всех необходимых условий для скачивания{' '}
                      <Text as="mark">готового документа/шаблона</Text>, Клиенту
                      предоставляется возможность предварительного просмотра
                      документа без возможности скачивания. Для продолжения
                      получения услуги, Клиенту необходимо согласиться с
                      «Условиями Оплаты» и перейти на следующий этап введения
                      банковских данных.
                    </Text>
                    <Text fontSize="sm">
                      4.4. Для осуществления расчетов между Клиентом и Сайтом,
                      предлагается опция оплаты через платежный сервис{' '}
                      <a
                        href="https://freedompay.money/kg"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: '#4299e1' }}
                      >
                        Freedom Pay
                      </a>{' '}
                      на Сайте.
                    </Text>
                    <Text fontSize="sm">
                      4.5. Клиент вправе оформить частичный или полный отказ от
                      получения услуг, при этом обязуется возместить
                      Администрации фактически понесенные расходы.
                    </Text>
                    <Text fontSize="sm">
                      4.6. Стоимость услуг указывается на Сайте. В случае
                      неверного указания стоимости услуг Администрация Сайта,
                      при первой возможности, информирует об этом Клиента.
                    </Text>
                    <Text fontSize="sm">
                      4.7. Стоимость услуг, указанных на Сайте, может быть
                      изменена Администрацией сайта в одностороннем порядке
                      (акции, скидки и т. д.).
                    </Text>
                    <Text fontSize="sm">
                      4.8. Подтверждением оказания услуг в рамках настоящего
                      сотрудничества является сохранение записи в Личном
                      кабинете Клиента о предоставленном шаблоне юридического
                      документа, а также то, что эта запись не оспорена Клиентом
                      в течение 24 (двадцати четырех) часов с момента
                      скачивания.{' '}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    5. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      5.1. Администрация Сайта освобождается от ответственности
                      за полное или частичное неисполнение обязательств в
                      следующих случаях, если неисполнение обязательств явилось
                      следствием действий непреодолимой силы, а именно: сбой
                      серверов Сайта, Freedom Pay или третьих лиц, объявление
                      чрезвычайного положения, чрезвычайной ситуации, локдауна,
                      пожара, наводнения, землетрясения, забастовки, войны,
                      действий органов государственной власти Кыргызской
                      Республики, прямо или косвенно влияющих на исполнение
                      Администрацией Сайта своих обязательств в рамках
                      настоящего Соглашения. Стороны признают и соглашаются, что
                      вышеперечисленные обстоятельства являются не зависящими от
                      воли Сторон.
                    </Text>
                    <Text fontSize="sm">
                      5.2. В случае наступления обстоятельств, предусмотренных в
                      настоящем Соглашении, срок выполнения Стороной
                      обязательств по настоящему Соглашению автоматически (без
                      уведомления Клиента) отодвигается соразмерно времени, в
                      течение которого действуют эти обстоятельства и их
                      последствия.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    6. ОТВЕТСТВЕННОСТЬ СТОРОН
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      6.1. За неисполнение и/или ненадлежащее исполнение своих
                      обязательств по настоящему Соглашению стороны несут
                      ответственность в соответствии с действующим
                      законодательством Кыргызской Республики.
                    </Text>
                    <Text fontSize="sm">
                      6.2. Клиент предупрежден о том, что Администрация Сайта не
                      несет ответственности за посещение и использование им
                      внешних ресурсов, ссылки на которые могут содержаться на
                      Сайте.
                    </Text>
                    <Text fontSize="sm">
                      6.3. Клиент согласен с тем, что Администрация Сайта не
                      несет ответственности перед Клиентом в связи с любыми
                      возможными или возникшими потерями или убытками,
                      связанными с содержанием Сайта или услугами, полученными
                      на Сайте или через внешние сайты или ресурсы, которыми
                      Клиент осознанно или не осознанно воспользовался.
                    </Text>
                    <Text fontSize="sm">
                      6.4. Администрация Сайта не несет никакой юридической
                      ответственности за нарушения Клиентом или третьим лицом
                      условий и обязательств, изложенных в составленном проекте
                      шаблона правового документа.
                    </Text>
                    <Text fontSize="sm">
                      6.5. Администрация Сайта не несет никакой юридической
                      ответственности связи с нарушением патентного и авторского
                      права, торговых знаков, фирменных наименований, рекламных
                      слоганов и иными случаями, в том числе относящимися к
                      недобросовестной конкуренции, ставшие возможными
                      вследствие умышленных или неумышленных действий Клиента в
                      процессе предоставления сведений в момент регистрации, в
                      момент заполнения анкеты (опросного листа) или иных
                      действий на Сайте.
                    </Text>
                    <Text as="mark" fontSize="sm">
                      6.6. Администрация Сайта не несет юридической
                      ответственности за некорректный, ошибочный ввод данных при
                      заполнении анкеты (опросного листа) к каждому документу,
                      выбранному Пользователем.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    7. РАЗРЕШЕНИЕ СПОРОВ ИЗ НАСТОЯЩЕГО СОГЛАШЕНИЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      7.1. Все споры, возникающие между Сторонами, будут
                      разрешаться путем переговоров и подготовки претензионных
                      писем. К претензии должны быть приложены документы,
                      обосновывающие предъявленные заинтересованной Стороной
                      требования (в случае их отсутствия у другой Стороны), и
                      документы, подтверждающие полномочия лица, подписавшего
                      претензию. Указанные документы представляются в форме
                      надлежащим образом заверенных копий. Претензия,
                      направленная без документов, подтверждающих полномочия
                      лица, ее подписавшего, считается непредъявленной и
                      рассмотрению не подлежит.
                    </Text>
                    <Text fontSize="sm">
                      7.2. Сторона, к которой направлена претензия, обязана
                      рассмотреть полученную претензию и о результатах уведомить
                      заинтересованную Сторону в письменной форме в течение трех
                      рабочих дней со дня получения претензии.
                    </Text>
                    <Text fontSize="sm">
                      7.3. Если Стороны не достигнут соглашения в ходе
                      переговоров, или на полученное претензионное письмо не
                      будет получен ответ в течение 15 (пятнадцати) календарных
                      дней, то спор подлежит разрешению в Международном
                      Третейском суде при Торгово-промышленной палате Кыргызской
                      Республики в соответствии с Регламентом Международного
                      Третейского суда при Торгово-промышленной палате
                      Кыргызской Республики тремя арбитрами. Применимым
                      материальным правом, в соответствии с которым будет
                      рассматриваться спор, является законодательство Кыргызской
                      Республики. Третейское разбирательство должно проводиться
                      на русском языке. Решение Третейского суда для Сторон
                      является окончательным.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      8.1. Все, что не оговорено в настоящем Соглашении, стороны
                      руководствуются действующим законодательством Кыргызской
                      Республики.
                    </Text>
                    <Text fontSize="sm">
                      8.2. Признание судом какого-либо положения Соглашения
                      недействительным не влечет недействительности иных
                      положений Соглашения.
                    </Text>
                    <Text fontSize="sm">
                      8.3. Настоящее пользовательское Соглашение представляет
                      собой электронный документ, то есть цифровую запись,
                      материальным носителем которого является файл бланка
                      настоящего Соглашения.
                    </Text>
                  </Box>
                </Box>

                <Box>
                  <Text as="b" fontSize="sm">
                    Клиент подтверждает, что ознакомлен со всеми пунктами
                    настоящего Соглашения и безусловно принимает их. Клиент
                    гарантирует, что он дееспособен и понимает значение и
                    правовые последствия взятых на себя обязательств,
                    предусмотренных настоящим Соглашением.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      </Container>
    </>
  );
};
