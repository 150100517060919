import { object, ref, string } from 'yup';

export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{6,20}$/;

export const SignupSchema = object().shape({
  phoneNumber: string()
    .matches(/^\d{9}$/, 'invalidPhoneNumber')
    .required('phoneNumberIsRequired'),
  email: string().email('invalidEmail').required('emailIsRequired'),
  password: string()
    .min(6, 'passwordLengthIsInvalid')
    .matches(PASSWORD_REGEX, 'passwordLengthIsInvalid')
    .required('passwordIsRequired'),
  confirmPassword: string()
    .oneOf([ref('password'), null], 'confirmPassword')
    .required('confirmPasswordIsRequired'),
});

export const SignInSchema = object().shape({
  email: string().email('invalidEmail').required('emailIsRequired'),
  password: string()
    .min(6, 'passwordLengthIsInvalid')
    .required('passwordIsRequired'),
});

export const ForgotPasswordSchema = object().shape({
  email: string().email('invalidEmail').required('emailIsRequired'),
});

export const ResetPasswordSchema = object().shape({
  password: string()
    .min(6, 'passwordLengthIsInvalid')
    .matches(PASSWORD_REGEX, 'passwordRegexIsInvalid')
    .required('passwordIsRequired'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'confirmPassword')
    .required('confirmPasswordIsRequired'),
});
