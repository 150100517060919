import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Show,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { FiHome, FiSettings, FiFolder, FiLogIn, FiEdit3 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
  resetAuthState,
  selectCurrentUser,
} from '../../features/auth/authSlice';

import { NavButton } from '../../components/common/NavButton/NavButton';

import {
  useForgotPasswordMutation,
  useLogOutMutation,
} from '../../features/auth/authApiSlice';
import { useAppDispatch } from '../../hooks';

export const Settings = () => {
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(undefined) as React.MutableRefObject<any>;
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [logOut] = useLogOutMutation();

  const handleHomePage = () => {
    navigate('/');
  };

  const handleDocuments = () => {
    navigate('/my-documents');
  };

  const handleResetPassword = async () => {
    if (!user) return;

    try {
      await forgotPassword({ email: user.email });

      onClose();
    } catch (err: any) {
      if ('data' in err) {
        toast({
          title: t(`ERROR.${err.data.message}`),
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const handleLogout = async () => {
    await logOut().unwrap();
    dispatch(resetAuthState());
    window.location.reload();
  };

  return (
    <>
      <Flex as="section" minH="100vh" bg="bg-canvas">
        <Show breakpoint="(min-width: 769px)">
          <Flex
            flex="1"
            bg="bg-surface"
            maxW={{ base: 'full', sm: 'xs' }}
            py={{ base: '6', sm: '8' }}
            px={{ base: '4', sm: '6' }}
            borderRight={1}
            borderStyle={'solid'}
            borderColor={'gray.200'}
          >
            <Stack justify="space-between" spacing="1" width="full">
              <Stack spacing="8" shouldWrapChildren>
                <Stack spacing="1">
                  <NavButton
                    label={t('userDocumentsPage.sideBar.home')}
                    icon={FiHome}
                    onClick={handleHomePage}
                  />
                </Stack>
                <Stack>
                  <Text fontSize="sm" color="subtle" fontWeight="medium">
                    {t('userDocumentsPage.sideBar.account')}
                  </Text>
                  <Stack spacing="1">
                    <NavButton
                      label={t('userDocumentsPage.sideBar.documents')}
                      icon={FiFolder}
                      onClick={handleDocuments}
                    />
                    <NavButton
                      label={t('userDocumentsPage.sideBar.settings')}
                      icon={FiSettings}
                      isActive={true}
                    />
                    <NavButton
                      label={t('userDocumentsPage.sideBar.logout')}
                      icon={FiLogIn}
                      onClick={handleLogout}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Flex>
        </Show>
        <Flex flex="1" bg={'gray.50'}>
          <Container maxW={'5xl'} mt={14} px={6}>
            <Flex>
              <Box>
                <Heading as="h2" size="lg" color="gray.700" ml={1}>
                  {t('accountSettingsPage.header')}
                </Heading>
              </Box>
            </Flex>
            <Flex flexWrap="wrap" mt={12}>
              <Box
                bg="white"
                border="1px"
                borderColor="gray.200"
                borderBottom={'none'}
                borderRadius="xl"
                borderBottomRadius={0}
                p={5}
                width={'100%'}
              >
                <Flex alignItems="center">
                  <Box>
                    <Heading as="h5" size="sm" color="gray.700">
                      {t(`accountSettingsPage.email`)}
                    </Heading>
                    <Text fontSize="sm" color="gray.400" pt={2}>
                      {user && user.email}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box
                bg="white"
                border="1px"
                borderColor="gray.200"
                borderRadius="xl"
                borderTopRadius={0}
                p={5}
                width={'100%'}
              >
                <Flex alignItems="center">
                  <Box>
                    <Flex alignItems={'center'}>
                      <Heading as="h5" size="sm" color="gray.700">
                        {t(`accountSettingsPage.password`)}
                      </Heading>
                      <Icon
                        as={FiEdit3}
                        ml={2}
                        color={'gray.400'}
                        onClick={onOpen}
                        cursor={'pointer'}
                      />
                    </Flex>

                    <Text fontSize="sm" color="gray.400" pt={2}>
                      ****************
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Container>
        </Flex>
      </Flex>
      <Show breakpoint="(max-width: 769px)">
        <Box
          position="sticky"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          pt={4}
          pb={6}
          boxShadow={'0px 0px 15px rgba(92, 96, 104, 0.3);'}
        >
          <Flex justifyContent={'space-around'}>
            <Box color="gray.500" textAlign={'center'}>
              <Link to={'/my-documents'}>
                <Icon as={FiFolder} fontSize={'1.3em'} />
                <Text fontSize={'0.9em'} lineHeight={0.5}>
                  {t('userDocumentsPage.sideBar.documents')}
                </Text>
              </Link>
            </Box>

            <Box color="blue.500" textAlign={'center'}>
              <Link to={'/account-settings'}>
                <Icon as={FiSettings} fontSize={'1.3em'} />
                <Text fontSize={'0.9em'} lineHeight={0.5}>
                  {t('userDocumentsPage.sideBar.settings')}
                </Text>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Show>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={'lg'}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader color={'red.600'} fontSize={'1em'}>
            {t('accountSettingsPage.passwordAlert.header')}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text>{t('accountSettingsPage.passwordAlert.body')}</Text>
            <Text pt={2}>{t('accountSettingsPage.passwordAlert.subject')}</Text>
            <Text pt={4}>
              {t('accountSettingsPage.passwordAlert.spamWarning')}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t('accountSettingsPage.passwordAlert.no')}
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={handleResetPassword}
              isLoading={isLoading}
            >
              {t('accountSettingsPage.passwordAlert.yes')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
