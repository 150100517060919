import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../../hooks';

import { selectCurrentUser } from '../../../features/auth/authSlice';

export const UnauthorizedOnlyRoutes = () => {
  const user = useAppSelector(selectCurrentUser);
  const location = useLocation();

  return user ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};
