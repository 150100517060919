import {
  Box,
  Button,
  CloseButton,
  Container,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Step } from '../common/Step/Step';

import { DocumentMock } from './DocumentMock';
import { FieldsWithNavigation } from './DocumentFields/FieldsWithNavigation';
import { IDocumentProps } from '../../pages/Document';
import { IDocumentProperty } from '../../dto/document';
import { useStep } from '../../hooks/useStep';

export const DocumentInProgress = ({ document }: IDocumentProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation();

  const steps = document.data.properties;

  const [currentStep, { goToNextStep, goToPrevStep }] = useStep({
    maxStep: steps.length,
  });

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <Container maxW={'7xl'} py={8}>
      <Show breakpoint="(min-width: 429px)">
        {isVisible && (
          <Box
            border={'1px solid #E2E7F4'}
            borderRadius="24px"
            background="#F6F8FF"
            pt={2}
            pb={3}
            px={5}
          >
            <HStack spacing="3">
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                justifyContent="left"
                spacing={{ base: '3', md: '6' }}
                width="full"
              >
                <Text fontSize="l" fontWeight={600} p={0}>
                  {t(`${document.name}.name`)}
                </Text>
              </Stack>
              <CloseButton
                alignSelf={{ base: 'self-start', sm: 'initial' }}
                aria-label="Close banner"
                onClick={onClose}
                color={'gray.400'}
              />
            </HStack>
            <Box>
              <Text fontSize="sm" p={0} maxWidth={{ base: '90%', xl: '80%' }}>
                {t(`${document.name}.description`)}
              </Text>
            </Box>
          </Box>
        )}
      </Show>

      <Show breakpoint="(min-width: 600px)">
        <Box pt={8} px={8}>
          <Box as="nav" aria-label="Steps" position="relative">
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              {document.data?.properties.map(
                (step: IDocumentProperty, index: number) => (
                  <Step
                    description={`${document.name}.${step.name}`}
                    key={index}
                    cursor="pointer"
                    isActive={currentStep === index}
                    isCompleted={currentStep > index}
                  />
                ),
              )}
            </Stack>
          </Box>
        </Box>
      </Show>
      <Show breakpoint="(max-width: 428px)">
        <Modal onClose={onClose} isOpen={isVisible} isCentered>
          <ModalOverlay />
          <ModalContent maxWidth="350px" borderRadius="16px">
            <ModalHeader>
              <Text fontSize="md" fontWeight={600} p={0} maxWidth="300px">
                {t(`${document.name}.name`)}
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="sm" p={0}>
                {t(`${document.name}.description`)}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>{t('common.close')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Show>

      <Flex>
        <FieldsWithNavigation
          documentName={document.name}
          fields={document.data.properties}
          currentStep={currentStep}
          previousStep={goToPrevStep}
          nextStep={goToNextStep}
        />

        <Show breakpoint="(min-width: 1200px)">
          <Spacer />
          <DocumentMock documentName={document.name} />
        </Show>
      </Flex>
    </Container>
  );
};
