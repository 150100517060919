import { Box, chakra, useRadio, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiCheck, HiX } from 'react-icons/hi';

export const RadioCard = (props: any) => {
  const { t } = useTranslation();
  const { rate, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps } =
    useRadio(radioProps);

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      bg="#F9FBFF"
      border={state.isChecked ? '1px solid #3778DB' : '1px solid #E2E6EF'}
      borderRadius="16px"
      minWidth="300px"
      minHeight="200px"
      position="relative"
    >
      <input {...getInputProps({})} hidden />
      <Box
        {...getCheckboxProps()}
        bg={state.isChecked ? 'white' : 'transparent'}
        border={state.isChecked ? '1px solid #3778DB' : '1px solid #C6CEE1'}
        w={6}
        h={6}
        rounded="full"
        position="absolute"
        top={4}
        right={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          w={4}
          h={4}
          bg={state.isChecked ? '#3778DB' : 'transparent'}
          rounded="full"
        />
      </Box>
      <Box p={'20px 25px 20px 20px'}>
        <Text fontWeight="bold">{t(`${rate.name}.name`)}</Text>
        <Box py="10px">
          <Box display="flex" pb={'10px'}>
            <Box pr={1}>
              {rate.docDownload ? (
                <HiCheck color={'#3778DB'} size="24px" />
              ) : (
                <HiX color={'#EC2525'} size="24px" />
              )}
            </Box>
            <Text fontSize="md">{t(`${rate.name}.docDownload`)}</Text>
          </Box>
          <Box display="flex" pb={'10px'}>
            <Box pr={1}>
              {rate.consult ? (
                <HiCheck color={'#3778DB'} size="24px" />
              ) : (
                <HiX color={'#EC2525'} size="24px" />
              )}
            </Box>

            <Text fontSize="md">{t(`${rate.name}.consult`)}</Text>
          </Box>
        </Box>
        <Box pt="8px">
          <Text
            fontSize="2xl"
            fontWeight="600"
            color={state.isChecked ? '#3778DB' : 'gray.400'}
          >
            {rate.price / 100} {t('checkOutPage.currency')}
          </Text>
        </Box>
      </Box>
    </chakra.label>
  );
};
