import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import React from 'react';
import notFoundImg from '../assets/404.svg';
import { useTranslation } from 'react-i18next';

import { Navbar } from '../components/common/Navbar';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Navbar />
      <Container maxW={'7xl'} minH={'90vh'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row' }}
        >
          <Stack flex={1} spacing={{ base: 3, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={500}
              fontSize={{ base: '2xl', lg: '5xl' }}
            >
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '30%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                }}
              >
                {t('notFoundPage.header')}
              </Text>
            </Heading>
            <Link to="/">
              <Text
                fontSize="l"
                px={1}
                textAlign={{ base: 'center', lg: 'left' }}
                color={'blue.500'}
              >
                {t('notFoundPage.subHeader')}
              </Text>
            </Link>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}
          >
            <Image src={notFoundImg} alt="Page not found img" />
          </Flex>
        </Stack>
      </Container>
    </Box>
  );
};
