import React from 'react';
import { Hide, Show } from '@chakra-ui/react';

import { BigScreenDoc } from './BigScreenDoc';
import { SmallScreenDoc } from './SmallScreenDoc';

export interface IUserDocumentProps {
  guid: string;
  userGuid: string;
  templateGuid: string;
  name: string;
  data: any; // todo: describe types
  isPaid: boolean;
  price: number;
  createdAt: Date;
  updatedAt: Date;
}

export const Document = (dto: IUserDocumentProps) => {
  return (
    <>
      <Show breakpoint="(min-width: 600px)">
        <BigScreenDoc {...dto} />
      </Show>
      <Hide breakpoint="(min-width: 599px)">
        <SmallScreenDoc {...dto} />
      </Hide>
    </>
  );
};
