import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks';
import { useState } from 'react';
import { addAnswer } from '../../../../features/user-document/userDocumentSlice';

export interface IRadioGroupField {
  name: string;
  tip?: string;
  documentName: string;
  value: string | number;
  fieldValues: Record<string, string | number>[];
  disableButton: (value: boolean) => void;
}

export const RadioGroupField = ({
  name,
  documentName,
  fieldValues,
  value,
  tip,
  disableButton,
}: IRadioGroupField) => {
  const [isVisible, setIsVisible] = useState(true);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = (value: string) => {
    dispatch(addAnswer({ [name]: value }));
    disableButton(false);
  };

  const onAlertClose = () => {
    setIsVisible(false);
  };

  return (
    <VStack
      spacing={4}
      align="flex-start"
      maxWidth={{
        base: '320px',
        sm: '400px',
        md: '450px',
        lg: '550px',
        xl: '600px',
      }}
    >
      {tip && isVisible && (
        <Box>
          <Alert status="info" variant="left-accent" borderRadius={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>{t('common.attention')}</AlertTitle>
              <AlertDescription>{t(`${documentName}.${tip}`)}</AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onAlertClose}
            />
          </Alert>
        </Box>
      )}

      <RadioGroup onChange={handleChange} value={value.toString()} size={'lg'}>
        <Stack>
          {fieldValues.map((fv) => (
            <Radio key={fv.value} value={fv.value.toString()}>
              <Text fontSize={{ base: 'md', lg: 'lg' }}>
                {t(`${documentName}.${fv.value}`)}
              </Text>
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </VStack>
  );
};
