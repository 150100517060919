import { useEffect } from 'react';
import { Center, Container, Spinner } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  resetAnswers,
  selectIsInProgress,
  setIsInProgress,
} from '../features/user-document/userDocumentSlice';
import { useFetchTemplateQuery } from '../features/templates/templateApiSlice';
import { IDocumentDto } from '../dto/document';

import { DocumentInProgress } from '../components/Document/DocumentInProgress';
import { DocumentComplete } from '../components/Document/DocumentComplete';
import { useAppDispatch } from '../hooks';

export interface IDocumentProps {
  document: IDocumentDto;
}

export const Document = () => {
  const isInProgress = useSelector(selectIsInProgress);
  const dispatch = useAppDispatch();
  const { docId } = useParams();
  const { data: template, isLoading } = useFetchTemplateQuery(docId);
  const location = useLocation();

  useEffect(() => {
    dispatch(resetAnswers());
    dispatch(setIsInProgress(true));
  }, [location]);

  if (isLoading || !template) {
    return (
      <Container maxW={'7xl'} paddingBottom={'80px'} minH={'80vh'}>
        <Center minHeight="600px">
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Container>
    );
  }

  return isInProgress ? (
    <DocumentInProgress document={template} />
  ) : (
    <DocumentComplete document={template} />
  );
};
