import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface IDocumentSliceState {
  answers: Record<string, string | number | string[]>;
  isInProgress: boolean;
}

const initialState: IDocumentSliceState = {
  answers: {},
  isInProgress: true,
};

const documentSlice = createSlice({
  name: 'documentSlice',
  reducers: {
    addAnswer: (
      state,
      action: PayloadAction<Record<string, string | number | string[]>>,
    ) => {
      const answers = state.answers;
      const answer = action.payload;
      state.answers = { ...answers, ...answer };
    },

    resetAnswers: (state) => {
      state.answers = {};
    },
    setIsInProgress: (state, action: PayloadAction<boolean>) => {
      state.isInProgress = action.payload;
    },
  },
  initialState,
});

export const { addAnswer, resetAnswers, setIsInProgress } =
  documentSlice.actions;
export const selectAnswers = (state: RootState) => state.document.answers;
export const selectIsInProgress = (state: RootState) =>
  state.document.isInProgress;

export default documentSlice.reducer;
