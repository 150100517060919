import {
  Box,
  Flex,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';

interface TestimonialProps {
  image: string;
  name: string;
  role: string;
  children: React.ReactNode;
  logo: string;
  link: string;
}

export const Testimonial = (props: TestimonialProps) => {
  const { image, name, role, children, logo, link } = props;
  return (
    <Stack
      as="blockquote"
      direction="row"
      spacing={{ base: '0', md: '8' }}
      flex="1"
      {...props}
    >
      <Flex w="full" direction="column">
        <Box textAlign="center" mb={4}>
          <a href={link} target="_blank" rel="noreferrer">
            <Img
              h={{ base: '12' }}
              w={'auto'}
              src={logo}
              alt="logo"
              objectFit="cover"
              display="inline-block"
            />
          </a>
        </Box>

        <Box minH={'220'}>
          <Text
            mt="3"
            fontSize="md"
            maxW="38rem"
            as="cite"
            whiteSpace={'pre-line'}
          >
            {`"${children}"`}
          </Text>
        </Box>
        <Box textAlign={'center'}>
          <Box pt={4}>
            <Img
              display={'inline-block'}
              mt="2"
              flexShrink={0}
              src={image}
              alt={name}
              objectFit="cover"
              w={{ base: '16' }}
              h={{ base: '16' }}
              rounded="full"
            />
          </Box>

          <Text as="cite" fontStyle="normal" fontWeight="bold" color="gray.800">
            {name}
          </Text>
          <Text fontSize="sm" color={mode('gray.600', 'gray.400')}>
            {role}
          </Text>
        </Box>
      </Flex>
    </Stack>
  );
};
