import { apiSlice } from '../../api/apiSlice';
import {
  CreateUserDocumentDto,
  InitPaymentDto,
  IPaymentStatusDto,
  IUserDocumentDto,
  RetryPaymentDto,
} from '../../dto/userDocument.dto';
import { ICheckPayment } from '../../dto/payment';

export const userDocumentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createUserDocument: builder.mutation<
      IUserDocumentDto,
      CreateUserDocumentDto
    >({
      query: (data) => ({
        url: 'user-document',
        method: 'POST',
        body: { ...data },
      }),
    }),
    initPayment: builder.mutation<IPaymentStatusDto, InitPaymentDto>({
      query: (data) => ({
        url: `/user-document/${data.userDocumentGuid}/init-payment`,
        method: 'POST',
        body: { ...data },
      }),
    }),
    retryPayment: builder.mutation<IPaymentStatusDto, RetryPaymentDto>({
      query: (data) => ({
        url: `/user-document/${data.userDocumentGuid}/retry-payment`,
        method: 'POST',
        body: { ...data },
      }),
    }),
    fetchUserDocument: builder.query<IUserDocumentDto, string | undefined>({
      query: (guid) => `/user-document/${guid}`,
    }),
    fetchDocumentIsPaid: builder.query<ICheckPayment, string | undefined>({
      query: (guid) => `/user-document/${guid}/check-payment`,
    }),

    fetchAllDocuments: builder.query<IUserDocumentDto[], void>({
      query: () => '/user-document/all',
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateUserDocumentMutation,
  useFetchUserDocumentQuery,
  useFetchDocumentIsPaidQuery,
  useFetchAllDocumentsQuery,
  useInitPaymentMutation,
  useRetryPaymentMutation,
} = userDocumentApiSlice;
