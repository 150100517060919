import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonText,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface IDocumentMock {
  documentName: string;
}

export const DocumentMock = ({ documentName }: IDocumentMock) => {
  const { t } = useTranslation();

  return (
    <Box minWidth="588px" paddingTop={10}>
      <Box
        border={'2px solid #E2E7F4'}
        borderRadius="16px"
        minWidth="100%"
        minHeight="840px"
        p={'40px'}
      >
        <Center>
          <Text fontWeight={600} fontSize={'sm'} py={'20px'}>
            {t(`${documentName}.name`)}
          </Text>
        </Center>
        <Flex my={'40px'}>
          <Skeleton
            startColor="gray.300"
            endColor="gray.300"
            height="8px"
            width="60px"
          />
          <Spacer />
          <Skeleton
            startColor="gray.300"
            endColor="gray.300"
            height="8px"
            width="90px"
          />
        </Flex>
        <Stack>
          <Skeleton startColor="gray.300" endColor="gray.300" height="8px" />
        </Stack>
        <Stack>
          <SkeletonText
            startColor="gray.300"
            endColor="gray.300"
            mt="4"
            noOfLines={7}
            spacing="4"
            isLoaded={false}
          />
        </Stack>
        <Stack py={3}>
          <SkeletonText
            startColor="gray.300"
            endColor="gray.300"
            mt="4"
            noOfLines={2}
            spacing="4"
          />
        </Stack>
        <Stack py={3}>
          <SkeletonText
            startColor="gray.300"
            endColor="gray.300"
            mt="4"
            noOfLines={8}
            spacing="4"
          />
        </Stack>
        <Stack>
          <Skeleton
            startColor="gray.300"
            endColor="gray.300"
            height="8px"
            my="8px"
          />
          <Skeleton
            startColor="gray.300"
            endColor="gray.300"
            height="8px"
            my="8px"
          />
        </Stack>
        <Stack py={4}>
          <SkeletonText
            startColor="gray.300"
            endColor="gray.300"
            mt="4"
            noOfLines={2}
            spacing="4"
          />
        </Stack>
      </Box>
    </Box>
  );
};
