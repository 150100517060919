import * as React from 'react';

import gauhar_tuleeva from '../assets/about-us/gauhar_tuleeva.png';
import compass_logo from '../assets/about-us/company-logo/compass.svg';
import daniyar from '../assets/about-us/daniyar.jpeg';
import ololo from '../assets/about-us/company-logo/ololo.png';
import tilek_mamutov from '../assets/about-us/tilek_mamutov.png';
import outtalent from '../assets/about-us/company-logo/outtalent.png';
import tilek_eshenaliev from '../assets/about-us/tilek_eshenaliev.jpeg';
import mashina_kg from '../assets/about-us/company-logo/mashina_colored.svg';

export interface IPartner {
  image: string;
  name: string;
  role: string;
  quote: React.ReactNode;
  logo: string;
  link: string;
}

export const Partners: IPartner[] = [
  {
    name: 'Гаухар Тулеева',
    image: gauhar_tuleeva,
    role: 'Compass College',
    quote: `Хочу подчеркнуть качества сотрудников и подходы  компании  «В рамках закона», благодаря которым Колледж «спит и работает спокойно»:
        - Профессионализм
        - Прозрачность
        - Про человеческие ценности
      Благодарю за ответственный подход к своей  работе, доверяем вам решение всех наших  вопросов в сфере налогов и права! Процветайте и будьте крепким плечом для тех, кто в вас верит`,
    logo: compass_logo,
    link: 'https://compasscollege.art/',
  },
  {
    name: 'Данияр Аманалиев',
    image: daniyar,
    role: 'ololoPlanet',
    quote: `Мои самые лучшие рекомендации всей команде фирмы «В рамках закона». Обращаюсь уже 6 лет и не было ни одного вопроса, который остался бы нерешенным. 
      
      Большие профессионалы и просто небезразличные люди, которые действительно всей душой болеют за дела клиентов.`,
    logo: ololo,
    link: 'https://ololo.city/ololoplanet',
  },
  {
    name: 'Тилек Ешеналиев',
    image: tilek_eshenaliev,
    role: 'mashina.kg',
    quote: `Юристам фирмы «В рамках закона» мы доверяем решение самых сложных задач в развитии нашего бизнеса.
     
            Я ценю наше сотрудничество и рад, что мы работаем вместе уже много лет.`,
    logo: mashina_kg,
    link: 'https://www.mashina.kg/',
  },
  {
    name: 'Тилек Мамутов',
    image: tilek_mamutov,
    role: 'Outtalent',
    quote: `Специалисты фирмы «В рамках закона» берутся за все возникающие юридические вопросы оперативно и профессионально, при этом ценят мое рабочее время и стараются без необходимости меня не отвлекать.
       
       Благодарен всем сотрудникам фирмы, желаю процветания, а также благодарных клиентов.`,
    logo: outtalent,
    link: 'https://www.outtalent.com/',
  },
];
