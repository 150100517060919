import React, { useCallback, useState } from 'react';
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Show,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { download } from '../../features/user-document/userDocumentService';
import { useFetchUserDocumentQuery } from '../../features/user-document/userDocumentApiSlice';

import doneImg from '../../assets/done.svg';
import { useSelector } from 'react-redux';
import {
  resetAuthState,
  selectCurrentUser,
} from '../../features/auth/authSlice';
import { UnauthorizedError } from '../../utils/error';
import { useAppDispatch } from '../../hooks';
import { FiDownloadCloud, FiFileText } from 'react-icons/fi';
import { fileName } from '../../utils/file';

export interface IDownloadPermittedProps {
  docId: string | undefined;
}

export const DownloadPermitted = ({ docId }: IDownloadPermittedProps) => {
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const { data: userDocument, isLoading } = useFetchUserDocumentQuery(docId);

  const handleDownload = useCallback(async () => {
    if (userDocument) {
      try {
        if (!user) {
          return;
        }
        setButtonIsLoading(true);
        const response = await download(userDocument.guid);
        const fileBlob = new Blob([response]);
        const url = URL.createObjectURL(fileBlob);
        const alink = document.createElement('a');

        alink.href = url;
        alink.download = fileName(t(`${userDocument.name}.name`)) + '.pdf';
        alink.click();

        setTimeout(() => setButtonIsLoading(false), 1200);
      } catch (error) {
        setButtonIsLoading(false);
        if (error instanceof UnauthorizedError) {
          dispatch(resetAuthState());
        }
      }
    }
  }, [userDocument]);

  if (isLoading || !userDocument) {
    return (
      <Container maxW={'7xl'} minH={'80vh'}>
        <Center minHeight="600px">
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Container>
    );
  }

  return (
    <Container maxW={'7xl'} minH={'80vh'}>
      <Flex
        justifyContent="space-between"
        pt={{ base: 14, lg: 14 }}
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        px={{ base: 4 }}
      >
        <Box flex={1}>
          <Heading
            as="h2"
            size={{ base: 'xl', lg: '2xl' }}
            noOfLines={1}
            color={'gray.700'}
          >
            {t(`downloadPage.documentIsReady`)}
          </Heading>
          <Flex alignItems="center" color="gray.400" gap={2} py={12}>
            <Show breakpoint="(min-width: 600px)">
              <Icon as={FiFileText} fontSize={'1.5em'} />
            </Show>
            <Text color="empahsized" fontWeight="medium">
              {t(`${userDocument.name}.name`)}
            </Text>
          </Flex>
          <Box
            backgroundColor="gray.50"
            borderRadius="lg"
            border="1px"
            borderColor="gray.200"
            cursor="pointer"
            onClick={handleDownload}
            maxW={{ base: '100%', sm: '220px', lg: '220px' }}
            p={4}
            mt={3}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              gap={2}
              color="gray.600"
            >
              {buttonIsLoading ? (
                <Spinner speed="0.65s" />
              ) : (
                <Icon as={FiDownloadCloud} fontSize={'1.4em'} />
              )}

              <Text>{t('downloadPage.download')} .pdf</Text>
            </Flex>
          </Box>
        </Box>
        <Show breakpoint="(min-width: 600px)">
          <Box>
            <Image alt={'Done Image'} src={doneImg} />
          </Box>
        </Show>
      </Flex>
    </Container>
  );
};
