import { Box, Flex, SimpleGrid, Text, Image } from '@chakra-ui/react';
import fastLoading from '../assets/fast-loading.svg';
import lawyer from '../assets/lawyer.svg';
import gdpr from '../assets/gdpr.svg';
import savingMoney from '../assets/saving-money.svg';
import { useTranslation } from 'react-i18next';

interface IFeatureProps {
  title: string;
  img: string;
  imgAlt?: string;
}
function Feature(props: IFeatureProps) {
  const { title, img, imgAlt } = props;

  return (
    <Box>
      <Flex justifyContent={'center'}>
        <Image src={img} alt={imgAlt ? imgAlt : 'Feature'} />
      </Flex>

      <Box>
        <Flex justifyContent={'center'}>
          <Text fontSize="lg" fontWeight={'bold'} textAlign={'center'}>
            {title}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

export default function Features() {
  const { t } = useTranslation();

  return (
    <SimpleGrid minChildWidth="196px" spacing={6}>
      <Feature img={fastLoading} title={t('homePage.features.minutes')} />
      <Feature img={lawyer} title={t('homePage.features.insurance')} />
      <Feature img={gdpr} title={t('homePage.features.guarantee')} />
      <Feature img={savingMoney} title={t('homePage.features.economy')} />
    </SimpleGrid>
  );
}
