import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { ResetPasswordDto } from '../../dto/user.dto';
import { ResetPasswordSchema } from '../../constants/validation';

import { useResetPasswordMutation } from '../../features/auth/authApiSlice';

import Logo from '../../assets/logo512.png';

export const ResetPassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const handleClickPassword = () => setShowPassword(!showPassword);
  const handleClickPasswordConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const { t } = useTranslation();
  const { search } = useLocation();
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
      toast({
        title: t(`resetPasswordPage.success`),
        description: t('resetPasswordPage.successDescription'),
        status: 'success',
        variant: 'subtle',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    }
  }, [isSuccess]);

  const initialValues: ResetPasswordDto = {
    password: '',
    passwordConfirmation: '',
  };

  const handleSubmit = async (values: ResetPasswordDto) => {
    const newPassword = values.password;
    const token = query.get('token');

    try {
      resetPassword({ token, newPassword }).unwrap();
    } catch (err: any) {
      if ('data' in err) {
        toast({
          title: t(`resetPasswordPage.error.${err.data.message}`),
          description: t('resetPasswordPage.error.tryAgain'),
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const resetPasswordForm = () => (
    <Stack
      spacing="8"
      maxW={{ base: 'md', xl: 'xl' }}
      pt={{ base: '36', xl: '48' }}
      px={{ base: '8' }}
    >
      <Stack spacing="6" align="center">
        <Image src={Logo} alt="Logo" boxSize="80px" />
        <Stack spacing="3" textAlign="center">
          <Heading>{t('resetPasswordPage.header')}</Heading>
          <Text color="gray.500">{t('resetPasswordPage.caption')}</Text>
        </Stack>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form noValidate={true}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl
                  id="password"
                  isInvalid={Boolean(formik.errors.password)}
                  isRequired
                >
                  <FormLabel htmlFor="password">Пароль</FormLabel>
                  <InputGroup size="md">
                    <Field
                      as={Input}
                      placeholder=""
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <InputRightElement width="4.5rem">
                      {showPassword ? (
                        <Icon
                          as={FiEyeOff}
                          color={'gray.500'}
                          onClick={handleClickPassword}
                        />
                      ) : (
                        <Icon
                          as={FiEye}
                          color={'gray.500'}
                          onClick={handleClickPassword}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {t(`resetPasswordPage.error.${formik.errors.password}`)}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="passwordConfirmation"
                  isInvalid={Boolean(formik.errors.passwordConfirmation)}
                  isRequired
                >
                  <FormLabel htmlFor="passwordConfirmation">
                    Повторите пароль
                  </FormLabel>
                  <InputGroup size="md">
                    <Field
                      as={Input}
                      placeholder=""
                      name="passwordConfirmation"
                      type={showPasswordConfirm ? 'text' : 'password'}
                    />
                    <InputRightElement width="4.5rem">
                      {showPasswordConfirm ? (
                        <Icon
                          as={FiEyeOff}
                          color={'gray.500'}
                          onClick={handleClickPasswordConfirm}
                        />
                      ) : (
                        <Icon
                          as={FiEye}
                          color={'gray.500'}
                          onClick={handleClickPasswordConfirm}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {t(
                      `resetPasswordPage.error.${formik.errors.passwordConfirmation}`,
                    )}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack spacing="4">
                <Button
                  variant="solid"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isLoading}
                >
                  {t('resetPasswordPage.header')}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
      <HStack justify="center" spacing="1">
        <Text fontSize="sm" color="gray.500">
          {t('signUpPage.alreadyAUser')}
          <Link to="/login" style={{ color: '#4299e1' }}>
            {' '}
            {t('signUpPage.login')}
          </Link>
        </Text>
      </HStack>
    </Stack>
  );

  return (
    <Flex
      minH={'100vh'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {resetPasswordForm()}
    </Flex>
  );
};
