import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import { Documents } from './pages/Documents/Documents';
import { Document } from './pages/Document';
import { Download } from './pages/Download';
import { NotFound } from './pages/NotFound';
// import { Templates } from './pages/Templates';
// import { TemplateCreate } from './pages/TemplateCreate';
import { ROLES } from './constants/user';
import { Login } from './pages/Auth/Login';
import { SignUp } from './pages/Auth/SignUp';
import { PrivateRoutes } from './components/common/Routes/PrivateRoutes';
import { UnauthorizedOnlyRoutes } from './components/common/Routes/UnauthorizedOnlyRoutes';
import { Checkout } from './pages/Checkout';
import { UserAgreement } from './pages/UserAgreement';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { PaymentTerms } from './pages/PaymentTerms';
import { AboutUs } from './pages/AboutUs';
import { Verify } from './pages/Verify';

import { ForgotPassword } from './pages/Auth/ForgotPassword';
import { ResetPassword } from './pages/Auth/ResetPassword';
import { MyDocuments } from './pages/Account/MyDocuments';
import { Settings } from './pages/Account/Settings';
import { UnauthorizedRoutes } from './components/common/Routes/UnauthorizedRoutes';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route element={<UnauthorizedRoutes />}>
            <Route path="/docs" element={<Documents />} />
            <Route path="/docs/:docId" element={<Document />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/payment-terms" element={<PaymentTerms />} />
          </Route>

          <Route
            element={
              <PrivateRoutes allowedRoles={[ROLES.CLIENT, ROLES.ADMIN]} />
            }
          >
            <Route path="/checkout/:docId" element={<Checkout />} />
            <Route path="/docs/:docId/download" element={<Download />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/my-documents" element={<MyDocuments />} />
            <Route path="/account-settings" element={<Settings />} />
          </Route>

          {/*todo: next feature*/}
          {/*<Route element={<PrivateRoutes allowedRoles={[ROLES.ADMIN]} />}>*/}
          {/*  <Route path="/templates" element={<Templates />} />*/}
          {/*  <Route path="/templates/new" element={<TemplateCreate />} />*/}
          {/*</Route>*/}
          <Route element={<UnauthorizedOnlyRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ChakraProvider>
    </div>
  );
}

export default App;
