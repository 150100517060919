import { apiSlice } from '../../api/apiSlice';

export interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IOtpDto {
  userGuid: string;
  otp: string;
}

export interface IResetPhoneNumberDto {
  phoneNumber: string;
}

export interface IForgotPasswordDto {
  email: string;
}

export interface IResetPasswordDto {
  newPassword: string;
  token: string | null;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials) => ({
        url: 'auth/signup',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    logOut: builder.mutation<null, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),

    verifyOtp: builder.mutation<ILoginResponse, IOtpDto>({
      query: (otpDto: IOtpDto) => ({
        url: 'auth/verify',
        method: 'POST',
        body: { ...otpDto },
      }),
    }),

    resendOtp: builder.mutation<null, void>({
      query: () => ({
        url: 'auth/resend-otp',
        method: 'POST',
      }),
    }),

    resetPhoneNumber: builder.mutation<null, IResetPhoneNumberDto>({
      query: (dto: IResetPhoneNumberDto) => ({
        url: 'auth/reset-phone',
        method: 'POST',
        body: { ...dto },
      }),
    }),

    forgotPassword: builder.mutation<null, IForgotPasswordDto>({
      query: (dto: IForgotPasswordDto) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: { ...dto },
      }),
    }),

    resetPassword: builder.mutation<null, IResetPasswordDto>({
      query: (dto: IResetPasswordDto) => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: { ...dto },
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogOutMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useResetPhoneNumberMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice;
