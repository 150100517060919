import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import {
  HiArrowRightOnRectangle,
  HiOutlineCog6Tooth,
  HiOutlineFolderOpen,
} from 'react-icons/hi2';
import { FiFolder, FiMenu, FiSettings, FiX } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';

import {
  resetAuthState,
  selectCurrentUser,
} from '../../features/auth/authSlice';
import { useLogOutMutation } from '../../features/auth/authApiSlice';
import { useAppDispatch } from '../../hooks';

import Logo from '../../assets/log_white.png';

const vramkahColor = '#0d1074';
export const NavbarTransparent = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const user = useSelector(selectCurrentUser);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logOut] = useLogOutMutation();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleOnLogoClick = () => {
    navigate('/');
  };

  const handleMyDocuments = async () => {
    navigate('/my-documents');
    onClose();
  };

  const handleMySettings = async () => {
    navigate('/account-settings');
    onClose();
  };

  const handleLogout = async () => {
    await logOut().unwrap();
    dispatch(resetAuthState());
    window.location.reload();
  };

  return (
    <Box as="section" bg={vramkahColor}>
      <Box as="nav" zIndex={'tooltip'} position="sticky">
        <Container maxW={'7xl'} py={{ base: '2' }}>
          <Flex justify="space-between">
            <HStack spacing="20">
              <HStack spacing="4" onClick={handleOnLogoClick} cursor="pointer">
                <Image src={Logo} alt="Logo" h="20px" />
                <Text
                  textAlign={useBreakpointValue({
                    base: 'center',
                    md: 'left',
                  })}
                  fontFamily={'heading'}
                  fontWeight={600}
                  color={'white'}
                >
                  Legal Docs
                </Text>
              </HStack>
              {isDesktop && (
                <HStack spacing="8">
                  <Box>
                    <Link to={'/docs?type=BUSINESS_CONTRACTS'}>
                      <Text color="white">{t('common.documents')}</Text>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={'/user-agreement'}>
                      <Text color="white">{t('common.termsOfUse')}</Text>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={'/privacy-policy'}>
                      <Text color="white">{t('common.privacyPolicy')}</Text>
                    </Link>
                  </Box>
                </HStack>
              )}
            </HStack>
            {isDesktop ? (
              <HStack spacing="4">
                {user ? (
                  <HStack spacing="4">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<AiOutlineUser />}
                        fontSize="1.5rem"
                        aria-label="Settings"
                        bg="white"
                        color={vramkahColor}
                        borderRadius={'full'}
                      />
                      <MenuList>
                        <MenuItem
                          color={vramkahColor}
                          icon={<FiFolder fontSize="1.25rem" />}
                          onClick={handleMyDocuments}
                        >
                          {t('common.myDocuments')}
                        </MenuItem>
                        <MenuItem
                          color={vramkahColor}
                          icon={<FiSettings fontSize="1.25rem" />}
                          onClick={handleMySettings}
                        >
                          {t('common.settings')}
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                          color={vramkahColor}
                          icon={<HiArrowRightOnRectangle fontSize="1.25rem" />}
                          onClick={handleLogout}
                        >
                          {t('common.logout')}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                ) : (
                  <Button
                    display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={'sm'}
                    fontWeight={600}
                    colorScheme="whiteAlpha"
                    color={'white'}
                    variant="ghost"
                    onClick={handleLogin}
                  >
                    {t('common.login')}
                  </Button>
                )}
              </HStack>
            ) : (
              <IconButton
                variant="ghost"
                icon={
                  isOpen ? (
                    <FiX fontSize="1.25rem" />
                  ) : (
                    <FiMenu fontSize="1.25rem" color={'white'} />
                  )
                }
                aria-label="Open Menu"
                onClick={onToggle}
              />
            )}
          </Flex>
        </Container>
      </Box>
      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerContent>
          <DrawerBody mt={'56px'} py={6}>
            <Stack spacing={4}>
              <Button
                leftIcon={<HiOutlineFolderOpen fontSize={'1.3em'} />}
                variant="ghost"
                width="100%"
                onClick={handleMyDocuments}
                justifyContent={'left'}
                display={'inline-flex'}
                color={'gray.600'}
              >
                {t('common.myDocuments')}
              </Button>
              {user && (
                <Button
                  leftIcon={<HiOutlineCog6Tooth fontSize={'1.3em'} />}
                  variant="ghost"
                  width="100%"
                  onClick={handleMySettings}
                  justifyContent={'left'}
                  display={'inline-flex'}
                  color={'gray.600'}
                >
                  {t('common.settings')}
                </Button>
              )}
              {user ? (
                <Button
                  leftIcon={<HiArrowRightOnRectangle fontSize={'1.3em'} />}
                  variant="ghost"
                  width="100%"
                  onClick={handleLogout}
                  justifyContent={'left'}
                  display={'inline-flex'}
                  color={'blue.400'}
                >
                  {t('common.logout')}
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  width="100%"
                  onClick={handleLogin}
                  justifyContent={'left'}
                  display={'inline-flex'}
                  color={'blue.400'}
                >
                  {t('common.login')}
                </Button>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
