import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface StepProps extends BoxProps {
  description: string;
  isCompleted: boolean;
  isActive: boolean;
}

export const Step = (props: StepProps) => {
  const { description, isActive, isCompleted, ...boxProps } = props;
  const { t } = useTranslation();

  return (
    <Box
      flex="1"
      py={{ base: '2', md: '3' }}
      ps={{ base: '3', md: '0' }}
      borderTopWidth={{ base: '0', md: '4px' }}
      borderLeftWidth={{ base: '4px', md: '0' }}
      borderColor={isActive || isCompleted ? 'blue.600' : 'inherit'}
      {...boxProps}
    >
      <Stack>
        <Text color="muted" pl={'4px'} fontSize={'14px'}>
          {t(description)}
        </Text>
      </Stack>
    </Box>
  );
};
