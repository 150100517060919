import {
  Box,
  Button,
  Container,
  Flex,
  Show,
  Spacer,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DocumentMock } from './DocumentMock';
import { IDocumentProps } from '../../pages/Document';
import { DocumentFields } from './DocumentFields/DocumentFields';

import { selectCurrentUser } from '../../features/auth/authSlice';
import {
  useCreateUserDocumentMutation,
  useInitPaymentMutation,
} from '../../features/user-document/userDocumentApiSlice';
import { selectAnswers } from '../../features/user-document/userDocumentSlice';
import { UserRole } from '../../dto/user.dto';

export const DocumentComplete = ({ document }: IDocumentProps) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [createUserDocument, { isLoading }] = useCreateUserDocumentMutation();
  const [initPayment, { isLoading: paymentIsLoading }] =
    useInitPaymentMutation();
  const user = useSelector(selectCurrentUser);
  const answers = useSelector(selectAnswers);
  const navigate = useNavigate();
  const fields = document.data.properties;
  const toast = useToast();

  const handleDisableButton = (value: boolean) => {
    setIsDisabled(value);
  };

  const handleCheckout = async () => {
    if (!user) {
      toast({
        title: t('ERROR.authenticationRequired'),
        status: 'warning',
        variant: 'subtle',
        position: 'top',
        isClosable: true,
      });
      return;
    }
    try {
      const { guid } = await createUserDocument({
        userGuid: user.guid,
        templateGuid: document.guid,
        data: answers,
      }).unwrap();

      if (!guid) {
        return;
      }

      if (user.role === UserRole.ADMIN || Number(document.price) === 0) {
        return navigate(`/docs/${guid}/download`, { replace: true });
      }

      try {
        const paymentInfo = await initPayment({
          userDocumentGuid: guid,
        }).unwrap();

        if (paymentInfo?.checkoutUrl) {
          window.location.replace(paymentInfo.checkoutUrl);
        }
      } catch (paymentInitError) {
        console.error(paymentInitError);
        return;
      }
    } catch (error) {
      console.error(error);
      //todo: add error message
      return;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Show breakpoint="(min-width: 600px)">
        <Stack p="4" boxShadow="lg" borderRadius="sm">
          <Container maxW={'7xl'}>
            <Stack direction="row" alignItems="center">
              <Text fontWeight="semibold" fontSize="xl">
                {t('documentPage.confirmData')}
              </Text>
            </Stack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              <Text fontSize={{ base: 'sm' }} textAlign={'left'} maxW={'4xl'}>
                {t('documentPage.termsAndConditions')}
                <Link to="/user-agreement" style={{ color: '#4299e1' }}>
                  {t('documentPage.userAgreement')}
                </Link>
                ,{' '}
                <Link to="/privacy-policy" style={{ color: '#4299e1' }}>
                  {t('documentPage.privacyPolicy')}
                </Link>
                {t('documentPage.and')}
                <Link to="/payment-terms" style={{ color: '#4299e1' }}>
                  {t('documentPage.paymentAndReturnTerms')}
                </Link>
              </Text>
              <Stack direction={{ base: 'column', md: 'row' }}>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  borderRadius={'12px'}
                  fontSize={'17px'}
                  width={'182px'}
                  py={'20px'}
                  onClick={handleCheckout}
                  loadingText={t('common.submitting')}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                >
                  {Number(document.price) === 0
                    ? t('documentPage.download')
                    : t('documentPage.pay')}
                </Button>
              </Stack>
            </Stack>
          </Container>
        </Stack>
      </Show>

      <Container
        maxW={'7xl'}
        minH={'100vh'}
        py={{ base: 4, lg: 8, md: 8 }}
        px={{ base: 6 }}
      >
        <Flex>
          <DocumentFields
            documentName={document.name}
            fields={fields}
            disableButton={handleDisableButton}
            handleCheckout={handleCheckout}
          />

          <Spacer />
          <Show breakpoint="(min-width: 1200px)">
            <DocumentMock documentName={document.name} />
          </Show>
        </Flex>
      </Container>
      <Show breakpoint="(max-width: 600px)">
        <Box
          position="sticky"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          p={6}
          borderRadius={'16px 16px 0px 0px'}
          boxShadow={'0px 0px 15px rgba(92, 96, 104, 0.3);'}
        >
          <VStack gap={4}>
            <Button
              colorScheme="blue"
              width="100%"
              borderRadius="10px"
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={handleCheckout}
            >
              {Number(document.price) === 0
                ? t('documentPage.download')
                : t('common.continue')}
            </Button>
            <Button
              colorScheme="gray"
              width="100%"
              borderRadius="10px"
              isLoading={isLoading}
              onClick={handleBack}
            >
              {t('common.back')}
            </Button>
          </VStack>
        </Box>
      </Show>
    </>
  );
};
