import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CheckboxGroup,
  CloseButton,
  Show,
  Text,
  VStack,
} from '@chakra-ui/react';
import { addAnswer } from '../../../../features/user-document/userDocumentSlice';

export interface ICheckboxField {
  name: string;
  tip?: string;
  documentName: string;
  value: string[];
  fieldValues: Record<string, string | number>[];
  disableButton: (value: boolean) => void;
}
export const CheckboxField = ({
  name,
  documentName,
  fieldValues,
  value,
  tip,
  disableButton,
}: ICheckboxField) => {
  const [isVisible, setIsVisible] = useState(true);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = (value: string[]) => {
    dispatch(addAnswer({ [name]: value }));
    disableButton(false);
  };

  const onAlertClose = () => {
    setIsVisible(false);
  };

  return (
    <VStack
      spacing={4}
      align="flex-start"
      maxWidth={{
        base: '320px',
        sm: '400px',
        md: '450px',
        lg: '550px',
        xl: '600px',
      }}
    >
      <Show breakpoint="(min-width: 429px)">
        {tip && isVisible && (
          <Box>
            <Alert status="info" variant="left-accent" borderRadius={4}>
              <AlertIcon />
              <Box>
                <AlertTitle>{t('common.attention')}</AlertTitle>
                <AlertDescription>
                  {t(`${documentName}.${tip}`)}
                </AlertDescription>
              </Box>
              <CloseButton
                alignSelf="flex-start"
                position="relative"
                right={-1}
                top={-1}
                onClick={onAlertClose}
              />
            </Alert>
          </Box>
        )}
      </Show>
      <CheckboxGroup defaultValue={value ?? []} onChange={handleChange}>
        <VStack align={'left'}>
          {fieldValues.map((fv) => (
            <Checkbox key={fv.value} value={fv.value.toString()}>
              <Text fontSize={{ base: 'md', lg: 'lg' }}>
                {t(`${documentName}.${fv.value}`)}
              </Text>
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>

      <Show breakpoint="(max-width: 428px)">
        {tip && isVisible && (
          <Box>
            <Alert status="info" variant="left-accent" borderRadius={4}>
              <AlertIcon />
              <Box>
                <AlertTitle>{t('common.attention')}</AlertTitle>
                <AlertDescription>
                  {t(`${documentName}.${tip}`)}
                </AlertDescription>
              </Box>
              <CloseButton
                alignSelf="flex-start"
                position="relative"
                right={-1}
                top={-1}
                onClick={onAlertClose}
              />
            </Alert>
          </Box>
        )}
      </Show>
    </VStack>
  );
};
