import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Stack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';

import {
  ILoginResponse,
  useLoginMutation,
} from '../../features/auth/authApiSlice';
import { useAppDispatch } from '../../hooks';
import { SignInDto } from '../../dto/user.dto';
import { setCredentials } from '../../features/auth/authSlice';
import { SignInSchema } from '../../constants/validation';

export const Login = () => {
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const dispatch = useAppDispatch();
  const initialValues: SignInDto = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: SignInDto) => {
    try {
      const userData = (await login(values).unwrap()) as ILoginResponse;
      dispatch(setCredentials({ token: userData.accessToken }));

      if (userData.refreshToken) {
        navigate(-1);
      }
    } catch (err: any) {
      if ('data' in err) {
        toast({
          title: t(`ERROR.${err.data.message}`),
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }
    }

    return;
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>{t('loginPage.header')}</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            {t('loginPage.subheader')}
            <Link to="/docs" style={{ color: '#4299e1' }}>
              {' '}
              {t('loginPage.features')}
            </Link>
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl
                    id="email"
                    isInvalid={Boolean(formik.errors.email)}
                    isRequired
                  >
                    <FormLabel>{t('loginPage.email')}</FormLabel>
                    <Field as={Input} placeholder="" name="email" />
                    <FormErrorMessage>
                      {t(`loginPage.error.${formik.errors.email}`)}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="password"
                    isInvalid={Boolean(formik.errors.password)}
                    isRequired
                  >
                    <FormLabel>{t('loginPage.password')}</FormLabel>
                    <Field as={Input} type="password" name="password" />
                    <FormErrorMessage>
                      {t(`loginPage.error.${formik.errors.password}`)}
                    </FormErrorMessage>
                  </FormControl>

                  <Stack spacing={10}>
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'center'}
                      justify={'space-between'}
                    >
                      <Checkbox>{t('loginPage.rememberMe')}</Checkbox>
                      <Link to="/forgot-password" style={{ color: '#4299e1' }}>
                        {' '}
                        {t('loginPage.forgotPassword')}
                      </Link>
                    </Stack>
                    <Button
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                      isLoading={isLoading}
                    >
                      {t('loginPage.login')}
                    </Button>
                  </Stack>
                  <Stack pt={1}>
                    <Text align={'center'} color="gray.500">
                      {t('loginPage.noAccount')}
                      <Link to="/signup" style={{ color: '#4299e1' }}>
                        {' '}
                        {t('loginPage.signUp')}
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};
