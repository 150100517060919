import React from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Hide,
  Icon,
  IconButton,
  Show,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { FiHome, FiPlus, FiSettings, FiFolder, FiLogIn } from 'react-icons/fi';

import { NavButton } from '../../components/common/NavButton/NavButton';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useFetchAllDocumentsQuery } from '../../features/user-document/userDocumentApiSlice';
import { Document } from '../../components/MyDocuments/Document';
import { resetAuthState } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../hooks';
import { useLogOutMutation } from '../../features/auth/authApiSlice';

export const MyDocuments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useFetchAllDocumentsQuery();
  const dispatch = useAppDispatch();
  const [logOut] = useLogOutMutation();

  const handleHomePage = () => {
    navigate('/');
  };

  const handleSettings = () => {
    navigate('/account-settings');
  };

  const handleAddDocument = () => {
    navigate('/docs');
  };

  const handleLogout = async () => {
    await logOut().unwrap();
    dispatch(resetAuthState());
    window.location.reload();
  };

  return (
    <>
      <Flex as="section" minH="100vh" bg="bg-canvas">
        <Show breakpoint="(min-width: 769px)">
          <Flex
            flex="1"
            bg="bg-surface"
            maxW={{ base: 'full', sm: 'xs' }}
            py={{ base: '6', sm: '8' }}
            px={{ base: '4', sm: '6' }}
            borderRight={1}
            borderStyle={'solid'}
            borderColor={'gray.200'}
          >
            <Stack justify="space-between" spacing="1" width="full">
              <Stack spacing="8" shouldWrapChildren>
                <Stack spacing="1">
                  <NavButton
                    label={t('userDocumentsPage.sideBar.home')}
                    icon={FiHome}
                    onClick={handleHomePage}
                  />
                </Stack>
                <Stack>
                  <Text fontSize="sm" color="subtle" fontWeight="medium">
                    {t('userDocumentsPage.sideBar.account')}
                  </Text>
                  <Stack spacing="1">
                    <NavButton
                      label={t('userDocumentsPage.sideBar.documents')}
                      icon={FiFolder}
                      isActive={true}
                    />
                    <NavButton
                      label={t('userDocumentsPage.sideBar.settings')}
                      icon={FiSettings}
                      onClick={handleSettings}
                    />
                    <NavButton
                      label={t('userDocumentsPage.sideBar.logout')}
                      icon={FiLogIn}
                      onClick={handleLogout}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Flex>
        </Show>
        <Flex flex="1" bg={'gray.50'}>
          <Container maxW={'5xl'} mt={14}>
            <Flex>
              <Box>
                <Heading as="h2" size="lg" color="gray.700" ml={1}>
                  {t('userDocumentsPage.header')}
                </Heading>
              </Box>
              <Spacer />
              <Box>
                <Show breakpoint="(min-width: 600px)">
                  <Button
                    leftIcon={<FiPlus />}
                    colorScheme="telegram"
                    fontWeight={'normal'}
                    fontSize={'sm'}
                    borderRadius={'xl'}
                    onClick={handleAddDocument}
                  >
                    {t('userDocumentsPage.addDocument')}
                  </Button>
                </Show>
                <Hide breakpoint="(min-width: 600px)">
                  <IconButton
                    aria-label="show-document"
                    colorScheme="blue"
                    borderRadius={'xl'}
                    icon={<FiPlus fontSize="1.25rem" />}
                    onClick={handleAddDocument}
                  />
                </Hide>
              </Box>
            </Flex>
            <VStack spacing={5} align="stretch" py={12}>
              {data && data.map((doc) => <Document {...doc} key={doc.guid} />)}
            </VStack>
          </Container>
        </Flex>
      </Flex>
      <Show breakpoint="(max-width: 769px)">
        <Box
          position="sticky"
          left="0"
          right="0"
          bottom="0"
          bg="white"
          pt={4}
          pb={6}
          boxShadow={'0px 0px 15px rgba(92, 96, 104, 0.3);'}
        >
          <Flex justifyContent={'space-around'}>
            <Box color="blue.500" textAlign={'center'}>
              <Link to={'/my-documents'}>
                <Icon as={FiFolder} fontSize={'1.3em'} />
                <Text fontSize={'0.9em'} lineHeight={0.5}>
                  {t('userDocumentsPage.sideBar.documents')}
                </Text>
              </Link>
            </Box>

            <Box color="gray.500" textAlign={'center'}>
              <Link to={'/account-settings'}>
                <Icon as={FiSettings} fontSize={'1.3em'} />
                <Text fontSize={'0.9em'} lineHeight={0.5}>
                  {t('userDocumentsPage.sideBar.settings')}
                </Text>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Show>
    </>
  );
};
