import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ForgotPasswordDto } from '../../dto/user.dto';
import { ForgotPasswordSchema } from '../../constants/validation';

import { useForgotPasswordMutation } from '../../features/auth/authApiSlice';

import Logo from '../../assets/logo512.png';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [forgotPassword, { isLoading, isSuccess }] =
    useForgotPasswordMutation();
  const toast = useToast();

  const initialValues: ForgotPasswordDto = {
    email: '',
  };

  const handleSubmit = async (values: ForgotPasswordDto) => {
    try {
      await forgotPassword(values);
    } catch (err: any) {
      if ('data' in err) {
        toast({
          title: t(`ERROR.${err.data.message}`),
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const resetPasswordForm = () => (
    <Stack
      spacing="8"
      maxW={{ base: 'md', xl: 'xl' }}
      pt={{ base: '36', xl: '48' }}
      px={{ base: '8' }}
    >
      <Stack spacing="6" align="center">
        <Image src={Logo} alt="Logo" boxSize="80px" />
        <Stack spacing="3" textAlign="center">
          <Heading>{t('forgotPasswordPage.header')}</Heading>
          <Text color="gray.500">{t('forgotPasswordPage.caption')}</Text>
        </Stack>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form noValidate={true}>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl
                  id="email"
                  isInvalid={Boolean(formik.errors.email)}
                  isRequired
                >
                  <FormLabel htmlFor="email">email</FormLabel>
                  <Field as={Input} placeholder="" name="email" />
                  <FormErrorMessage>
                    {t(`forgotPasswordPage.error.${formik.errors.email}`)}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack spacing="4">
                <Button
                  variant="solid"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isLoading}
                >
                  {t('forgotPasswordPage.header')}
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
      <HStack justify="center" spacing="1">
        <Text fontSize="sm" color="gray.500">
          {t('signUpPage.alreadyAUser')}
          <Link to="/login" style={{ color: '#4299e1' }}>
            {' '}
            {t('signUpPage.login')}
          </Link>
        </Text>
      </HStack>
    </Stack>
  );

  const resetSuccessMessage = () => (
    <Stack
      spacing="8"
      maxW={{ base: 'md', xl: 'xl' }}
      pt={{ base: '36', xl: '48' }}
      px={{ base: '8' }}
    >
      <Stack spacing="6" align="center">
        <Image src={Logo} alt="Logo" boxSize="80px" />
        <Stack spacing="3" textAlign="center">
          <Heading>{t('forgotPasswordPage.resetSuccessHeader')}</Heading>
          <Text color="gray.500">{t('forgotPasswordPage.resetSuccess')}</Text>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Flex
      minH={'100vh'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      {isSuccess ? resetSuccessMessage() : resetPasswordForm()}
    </Flex>
  );
};
