import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { RootState } from '../store';
import { resetAuthState, setCredentials } from '../features/auth/authSlice';

export interface ReAuthResponse {
  accessToken: string;
  refreshToken: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);

    if (refreshResult?.data) {
      const { accessToken }: ReAuthResponse =
        refreshResult.data as ReAuthResponse;

      api.dispatch(setCredentials({ token: accessToken }));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(resetAuthState());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  tagTypes: ['Templates', 'Documents'],
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
