import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { IPricing, PricingList } from '../constants/pricing';
import { HiCheck, HiX } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

function Feature(props: IPricing) {
  const { title, features, price, isRecommended } = props;
  const { t } = useTranslation();

  return (
    <Box
      bg="#FBFCFF"
      height="275px"
      borderRadius="16px"
      border={'1px solid'}
      borderColor={isRecommended ? '#3778DB' : '#E2E6EF'}
      position="relative"
    >
      <Box paddingLeft={'28px'} paddingTop={'24px'} paddingBottom={1}>
        <Flex justifyContent="space-between">
          <Heading as="h6" fontSize={'24px'} fontWeight={'600'}>
            {t(title)}
          </Heading>
          {isRecommended && (
            <Tag
              size={'md'}
              key={'md'}
              borderRadius="full"
              color={'#E7EDF6'}
              marginRight={6}
              p={'10px'}
            >
              <TagLabel color={'#3778DB'}>Рекомендуем</TagLabel>
            </Tag>
          )}
        </Flex>
      </Box>
      {features.map((feature) => (
        <Box key={feature.name} paddingLeft={'32px'} paddingTop={'16px'}>
          <Flex>
            <Center paddingRight={'12px'}>
              {feature.isIncluded ? (
                <HiCheck color={'#3778DB'} size="24px" />
              ) : (
                <HiX color={'#EC2525'} size="24px" />
              )}
            </Center>
            <Text
              fontSize={'17px'}
              fontWeight={400}
              lineHeight={'21px'}
              maxWidth={'300px'}
            >
              {t(feature.name)}
            </Text>
          </Flex>
        </Box>
      ))}
      <Box>
        <Text
          fontSize={'32px'}
          fontWeight={600}
          color={'#3778DB'}
          position={'absolute'}
          bottom={4}
          paddingLeft={6}
        >
          {price}
        </Text>
      </Box>
    </Box>
  );
}

export default function Pricing() {
  return (
    <Box>
      <SimpleGrid minChildWidth="340px" spacing="24px">
        {PricingList.map((pricing) => (
          <Feature
            key={pricing.title}
            title={pricing.title}
            features={pricing.features}
            price={pricing.price}
            isRecommended={pricing.isRecommended}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
