import React, { useCallback, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FiDownload, FiEye } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../hooks';

import { download } from '../../features/user-document/userDocumentService';
import { fileName } from '../../utils/file';
import { UnauthorizedError } from '../../utils/error';
import { resetAuthState } from '../../features/auth/authSlice';

import { IUserDocumentProps } from './Document';

export const BigScreenDoc = (dto: IUserDocumentProps) => {
  const [downLoadButtonIsLoading, setDownloadButtonIsLoading] = useState(false);
  const [showButtonIsLoading, setShowButtonIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDownload = useCallback(async () => {
    try {
      setDownloadButtonIsLoading(true);
      const response = await download(dto.guid);
      const fileBlob = new Blob([response]);
      const url = URL.createObjectURL(fileBlob);
      const alink = document.createElement('a');

      alink.href = url;
      alink.download = fileName(t(`${dto.name}.name`)) + '.pdf';
      alink.click();

      setTimeout(() => setDownloadButtonIsLoading(false), 1000);
    } catch (error) {
      setDownloadButtonIsLoading(false);
      if (error instanceof UnauthorizedError) {
        dispatch(resetAuthState());
      }
    }
  }, [dto.guid]);

  const handleShowPdf = useCallback(async () => {
    try {
      setShowButtonIsLoading(true);
      const response = await download(dto.guid);
      const fileBlob = new Blob([response], {
        type: 'application/pdf',
      });

      const url = URL.createObjectURL(fileBlob);

      window.open(url, '_blank');

      setTimeout(() => setShowButtonIsLoading(false), 1000);
    } catch (error) {
      setShowButtonIsLoading(false);
      if (error instanceof UnauthorizedError) {
        dispatch(resetAuthState());
      }
    }
  }, [dto.guid]);

  return (
    <Box bg="white" border="1px" borderColor="gray.200" borderRadius="xl" p={5}>
      <Flex alignItems="center" gap={5}>
        <Box flex="1">
          <Heading as="h5" size="sm" color="gray.700">
            {t(`${dto.name}.name`)}
          </Heading>
          <Text fontSize="sm" color="gray.400" pt={2}>
            {`${t('userDocumentsPage.created')}: ${format(
              new Date(dto.createdAt),
              'dd MMMM yyyy, HH:mm',
              { locale: ru },
            )}`}
          </Text>
        </Box>
        <Box>
          {dto.isPaid ? (
            <Tag
              size="sm"
              borderRadius="full"
              variant="outline"
              colorScheme="green"
              backgroundColor="green.50"
            >
              <TagLabel> {t('userDocumentsPage.statusReady')}</TagLabel>
            </Tag>
          ) : (
            <Tag
              size="sm"
              borderRadius="full"
              variant="outline"
              colorScheme="blue"
              backgroundColor="blue.50"
            >
              <TagLabel> {t('userDocumentsPage.statusInProgress')}</TagLabel>
            </Tag>
          )}
        </Box>
        <Box>
          <HStack spacing={3}>
            <IconButton
              variant="ghost"
              aria-label="show-document"
              icon={
                showButtonIsLoading ? (
                  <Spinner color={'gray.500'} size={'sm'} />
                ) : (
                  <FiEye fontSize="1.25rem" />
                )
              }
              color={'gray.500'}
              onClick={handleShowPdf}
              isDisabled={!dto.isPaid}
            />

            <IconButton
              variant="ghost"
              aria-label="download-document"
              icon={
                downLoadButtonIsLoading ? (
                  <Spinner color={'gray.500'} size={'sm'} />
                ) : (
                  <FiDownload fontSize="1.25rem" />
                )
              }
              color={'gray.500'}
              onClick={handleDownload}
              isDisabled={!dto.isPaid}
            />
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};
