import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAnswers } from '../../../features/user-document/userDocumentSlice';
import { IDocumentProperty } from '../../../dto/document';
import { TEMPLATE_FIELD_TYPE } from '../../../constants/doc-type';
import { NumberField } from './Fields/NumberField';
import { RadioGroupField } from './Fields/RadioGroupField';
import { CheckboxField } from './Fields/CheckboxField';

interface IDocumentFields {
  documentName: string;
  fields: IDocumentProperty[];
  disableButton: (value: boolean) => void;
  handleCheckout: () => void;
}

export const DocumentFields = ({
  documentName,
  fields,
  disableButton,
  handleCheckout,
}: IDocumentFields) => {
  const answers = useSelector(selectAnswers);

  const { t } = useTranslation();

  return (
    <Box>
      {fields.map((field, index) => (
        <Box key={index} pb={5}>
          <Text
            fontSize={{ base: 'lg', lg: 'xl', md: 'xl' }}
            fontWeight={600}
            py={4}
          >
            {t(`${documentName}.${field.name}`)}
          </Text>
          {field.type === TEMPLATE_FIELD_TYPE.RADIO && (
            <RadioGroupField
              {...field}
              documentName={documentName}
              value={answers[field.name] as string}
              disableButton={disableButton}
            />
          )}

          {field.type === TEMPLATE_FIELD_TYPE.NUMBER_INPUT && (
            <NumberField
              {...field}
              documentName={documentName}
              value={answers[field.name] as string}
              disableButton={disableButton}
            />
          )}

          {field.type === TEMPLATE_FIELD_TYPE.CHECKBOX && (
            <CheckboxField
              {...field}
              documentName={documentName}
              value={answers[field.name] as string[]}
              disableButton={disableButton}
            />
          )}
        </Box>
      ))}

      <Box pt={8}>
        <Button colorScheme="blue" minW={'150px'} onClick={handleCheckout}>
          {t('documentPage.download')}
        </Button>
      </Box>
    </Box>
  );
};
