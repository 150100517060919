import { configureStore } from '@reduxjs/toolkit';
import authReducer, { IAuthState } from './features/auth/authSlice';
import { apiSlice } from './api/apiSlice';
import documentReducer from './features/user-document/userDocumentSlice';

export interface IAppState {
  auth: IAuthState;
}

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    document: documentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
