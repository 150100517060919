import {
  Box,
  Container,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import React from 'react';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Box bg={'#172334'} color={'white'}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '2fr 2fr', md: '2fr 1fr 2fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={2}>
            <Box>
              <Text fontSize={'24px'} fontWeight={600}>
                Legal Docs
              </Text>
            </Box>
            <Box>
              <Text fontSize={'sm'}>+996990337700</Text>
              <Text fontSize={'sm'}>+996703932001</Text>
              <Text fontSize={'sm'}>legaldocs.kg@gmail.com</Text>
            </Box>
            <HStack align={'flex-start'}>
              <Link
                href="https://www.instagram.com/v_ramkah_z/"
                target="_blank"
              >
                <Icon as={AiFillInstagram} h={'25px'} w={'25px'} />
              </Link>
              <Link
                href="https://www.facebook.com/vramkahzakona"
                target="_blank"
              >
                <Icon as={AiFillFacebook} h={'25px'} w={'25px'} />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCj3-VwHTfn8r4zjo-J2qbdA"
                target="_blank"
              >
                <Icon as={AiFillYoutube} h={'25px'} w={'25px'} />
              </Link>
            </HStack>
            <Box pt={2}>
              <Text fontSize={'sm'}>
                © {new Date().getFullYear()} {t('common.allRightsReserved')}
              </Text>
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <Link href={'/about-us'}>{t('common.aboutUs')}</Link>
            <Link href={'#'}>{t('common.advantages')}</Link>
            <Link href={'#'}>{t('common.pricing')}</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <Link href={'/user-agreement'}>{t('common.termsOfUse')}</Link>
            <Link href={'/privacy-policy'}>{t('common.privacyPolicy')}</Link>
            <Link href={'/payment-terms'}>{t('common.termsOfPayment')}</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
