import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Show,
  Stack,
  Text,
} from '@chakra-ui/react';
import { HiCreditCard } from 'react-icons/hi';

import transactionError from '../../assets/transaction-error.svg';
import { useRetryPaymentMutation } from '../../features/user-document/userDocumentApiSlice';
import { IDownloadPermittedProps } from './DownloadPermitted';

export const DownloadForbidden = ({ docId }: IDownloadPermittedProps) => {
  const { t } = useTranslation();
  const [retryPayment, { isLoading }] = useRetryPaymentMutation();

  const handlePayment = async () => {
    if (!docId) return;

    try {
      const paymentInfo = await retryPayment({
        userDocumentGuid: docId,
      }).unwrap();

      if (paymentInfo?.checkoutUrl) {
        window.location.replace(paymentInfo.checkoutUrl);
      }
    } catch (e) {
      return;
    }
  };

  return (
    <Container maxW={'7xl'} minH={'80vh'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 4, md: 4 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={500}
            fontSize={{ base: '3xl', lg: '5xl' }}
            textAlign={{ base: 'center', lg: 'left', md: 'left' }}
          >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
              }}
            >
              {t('downloadPage.transactionError')}
            </Text>
          </Heading>
          <Text
            fontSize={{ base: 'l', lg: 'xl', md: 'xl' }}
            px={1}
            pb={8}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            {t('downloadPage.transactionErrorDescription')}
          </Text>
          <Show breakpoint="(min-width: 429px)">
            <Button
              size="lg"
              variant="outline"
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handlePayment}
              color={'gray.600'}
              width={{ base: '300px' }}
            >
              <Icon as={HiCreditCard} h={'20px'} w={'20px'} marginRight={1} />
              {t('downloadPage.pay')}
            </Button>
          </Show>
          <Show breakpoint="(max-width: 428px)">
            <Button
              size="lg"
              variant="outline"
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handlePayment}
              color={'gray.600'}
            >
              <Icon as={HiCreditCard} h={'20px'} w={'20px'} marginRight={1} />
              {t('downloadPage.pay')}
            </Button>
          </Show>
        </Stack>
        <Flex flex={1}>
          <Image alt={'Transaction error image'} src={transactionError} />
        </Flex>
      </Stack>
    </Container>
  );
};
