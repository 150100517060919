import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addAnswer } from '../../../../features/user-document/userDocumentSlice';
import { useAppDispatch } from '../../../../hooks';

interface INumberInput {
  documentName: string;
  name: string;
  tip?: string;
  value: string | number | string[];
  disableButton: (value: boolean) => void;
}

export const NumberField = ({
  documentName,
  name,
  tip,
  value,
  disableButton,
}: INumberInput) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isInputInvalid, setIsInputInvalid] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onAlertClose = () => {
    setIsVisible(false);
  };

  const numberInputChange = useCallback((value: string) => {
    if (!value) {
      disableButton(true);
      dispatch(addAnswer({ [name]: value }));
      setIsInputInvalid(true);
      return;
    }

    dispatch(addAnswer({ [name]: value }));
    setIsInputInvalid(false);
    disableButton(false);
  }, []);

  return (
    <VStack
      spacing={4}
      align="flex-start"
      maxWidth={{
        base: '320px',
        sm: '400px',
        md: '450px',
        lg: '550px',
        xl: '600px',
      }}
    >
      {tip && isVisible && (
        <Box>
          <Alert status="info" variant="left-accent" borderRadius={4}>
            <AlertIcon />
            <Box>
              <AlertTitle>{t('common.attention')}</AlertTitle>
              <AlertDescription>{t(`${documentName}.${tip}`)}</AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onAlertClose}
            />
          </Alert>
        </Box>
      )}

      <Box minWidth={'100%'}>
        <NumberInput
          value={value as number}
          onChange={numberInputChange}
          isInvalid={isInputInvalid}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
    </VStack>
  );
};
