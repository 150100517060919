import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getAuthToken, setAuthToken } from '../utils/auth';
import { ReAuthResponse } from './apiSlice';
import { UnauthorizedError } from '../utils/error';

export const fileAuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/pdf',
    Accept: 'application/pdf',
  },
  responseType: 'arraybuffer',
});

fileAuthApi.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (!config?.headers) {
      throw new Error(
        `Expected 'config' and 'config.headers' not to be undefined`,
      );
    }

    config.headers['Authorization'] = 'Bearer ' + getAuthToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

fileAuthApi.interceptors.response.use(
  function (config) {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    try {
      if (error.response.status === 401) {
        const response = await axios.get<ReAuthResponse>(
          `${process.env.REACT_APP_API_URL}/auth/refresh`,
          {
            withCredentials: true,
          },
        );
        setAuthToken(response.data.accessToken);

        return fileAuthApi.request(originalRequest);
      }
    } catch (error: unknown | AxiosError) {
      if (axios.isAxiosError(error)) {
        if ((error as AxiosError).response?.status === 401) {
          throw new UnauthorizedError('Unauthorized');
        }
      } else {
        return Promise.reject(error);
      }
    }
  },
);
