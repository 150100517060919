export interface IPricingFeatures {
  name: string;
  isIncluded: boolean;
}
export interface IPricing {
  title: string;
  features: IPricingFeatures[];
  price: string;
  isRecommended?: boolean;
}

export const PricingList: IPricing[] = [
  {
    title: 'checkOutPage.baseRate.name',
    features: [
      {
        name: 'checkOutPage.baseRate.docDownload',
        isIncluded: true,
      },
      {
        name: 'checkOutPage.baseRate.consult',
        isIncluded: false,
      },
    ],
    price: '300-500 сом',
  },
  {
    title: 'checkOutPage.optimalRate.name',
    features: [
      {
        name: 'checkOutPage.baseRate.docDownload',
        isIncluded: true,
      },
      {
        name: 'checkOutPage.baseRate.consult',
        isIncluded: true,
      },
    ],
    price: '1000 сом',
    isRecommended: true,
  },
];

export interface IPaymentPlan {
  name: string;
  code: string;
  docDownload: boolean;
  consult: boolean;
  price: number;
}

export const paymentPlans: IPaymentPlan[] = [
  {
    name: 'checkOutPage.baseRate',
    code: 'BASE',
    docDownload: true,
    consult: false,
    price: 50000,
    // price: 2000,
  },
  {
    name: 'checkOutPage.optimalRate',
    code: 'OPTIMAL',
    docDownload: true,
    consult: true,
    price: 100000,
  },
];
