import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

import useDebounce from '../../hooks/useDebounce';

export const SearchFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(
    searchParams.get('search') || '',
  );
  const { t } = useTranslation();
  const isError = inputValue.length !== 0 && inputValue.length < 3;

  useDebounce(
    () => {
      if (inputValue.length >= 3) {
        const currentParams = Object.fromEntries([...searchParams]);
        const type = currentParams.type || '';
        setSearchParams({ search: inputValue, type });
      }
    },
    [inputValue],
    800,
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    const currentParams = Object.fromEntries([...searchParams]);
    const type = currentParams.type || '';

    if (!inputValue) {
      setSearchParams({ type, search: '' });
    }
  };

  const handleResetSearch = () => {
    setInputValue('');
    const currentParams = Object.fromEntries([...searchParams]);
    const type = currentParams.type || '';
    setSearchParams({ type, search: '' });
  };

  return (
    <FormControl isInvalid={isError} pb={4}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.400"
          fontSize="1.2em"
        >
          <SearchIcon color={isError ? 'red.300' : 'gray.400'} />
        </InputLeftElement>
        <Input
          name="search"
          value={inputValue}
          placeholder={t('common.search')}
          focusBorderColor={isError ? 'red.300' : 'blue.400'}
          onChange={handleInputChange}
          borderRadius="8px"
          errorBorderColor="red.300"
        />
        <InputRightElement>
          {inputValue && (
            <CloseIcon
              color={isError ? 'red.300' : 'gray.400'}
              onClick={handleResetSearch}
              cursor="pointer"
            />
          )}
        </InputRightElement>
      </InputGroup>
      {!isError ? (
        <FormHelperText pl={2} fontSize="xs">
          {t('documentsPage.searchFieldAnnotation')}
        </FormHelperText>
      ) : (
        <FormErrorMessage pl={2} fontSize="xs">
          {t('documentsPage.searchFieldValidationError')}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
