import { Center, Container, Spinner } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useFetchDocumentIsPaidQuery } from '../features/user-document/userDocumentApiSlice';
import { DownloadPermitted } from '../components/Download/DownloadPermitted';
import { DownloadForbidden } from '../components/Download/DownloadForbidden';

export const Download = () => {
  const { docId } = useParams();

  const { data: payment, isLoading: isPaymentLoading } =
    useFetchDocumentIsPaidQuery(docId);

  if (isPaymentLoading || !payment) {
    return (
      <Container maxW={'7xl'} paddingBottom={'80px'} minH={'80vh'}>
        <Center minHeight="600px">
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Container>
    );
  }

  return payment.isPaid ? (
    <DownloadPermitted docId={docId} />
  ) : (
    <DownloadForbidden docId={docId} />
  );
};
