import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
} from '@chakra-ui/react';
import heroImg from '../assets/hero.svg';
import startupLife from '../assets/startup-life.svg';
import DocTypesCards from '../components/DocTypesCards';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import Footer from '../components/common/Footer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navbar } from '../components/common/Navbar';
import { SearchFilter } from '../components/common/Home/SearchFilter';

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateDocument = () => {
    navigate('/docs?type=EMPLOYMENT_CONTRACTS');
  };

  return (
    <Box>
      <Navbar />
      <Container maxW={'7xl'} paddingBottom={'80px'} px={6}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={500}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
            >
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '30%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                }}
              >
                {t('homePage.heroHeader')}
              </Text>
            </Heading>
            <Text fontSize="xl">{t('homePage.heroSubHeader')}</Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                size="lg"
                fontWeight="bold"
                px={6}
                colorScheme="blue"
                borderRadius="12px"
                onClick={handleCreateDocument}
              >
                {t('homePage.heroCallToAction')}
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}
          >
            <Image src={startupLife} alt="Hero img" />
          </Flex>
        </Stack>
        <Stack>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Text
              as={'span'}
              position={'relative'}
              fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }}
              fontWeight="500"
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
              }}
            >
              {t('homePage.searchDocuments')}
            </Text>
            <SearchFilter />
          </Stack>
        </Stack>
        <Stack>
          <DocTypesCards />
        </Stack>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 14 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}
          >
            <Image src={heroImg} alt="Startup Life" />
          </Flex>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={500}
              fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            >
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '30%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                }}
              >
                {t('homePage.aboutService')}
              </Text>
            </Heading>
            <Text fontSize="xl">{t('homePage.aboutServiceDescription')}</Text>
          </Stack>
        </Stack>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Text
            as={'span'}
            position={'relative'}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            fontWeight="500"
            _after={{
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
            }}
          >
            {t('homePage.advantages')}
          </Text>
          <Features />
        </Stack>
        <Stack flex={1} spacing={{ base: 5, md: 10 }} paddingTop={'120px'}>
          <Text
            as={'span'}
            position={'relative'}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            fontWeight="500"
            _after={{
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
            }}
          >
            {t('homePage.pricing')}
          </Text>
          <Pricing />
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
}
