import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  useColorModeValue,
  InputLeftAddon,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import { useSignupMutation } from '../../features/auth/authApiSlice';
import { useAppDispatch } from '../../hooks';
import { SignUpDto } from '../../dto/user.dto';
import { setCredentials } from '../../features/auth/authSlice';

import { SignupSchema } from '../../constants/validation';

export const SignUp = () => {
  const [signup, { isLoading }] = useSignupMutation();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const dispatch = useAppDispatch();

  const initialValues: SignUpDto = {
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values: SignUpDto) => {
    try {
      const userData = await signup(values).unwrap();

      dispatch(setCredentials({ token: userData.accessToken }));

      if (userData.refreshToken) {
        navigate('/verify');
      }
    } catch (error: any) {
      if ('data' in error) {
        toast({
          title: t(`ERROR.${error.data.message}`),
          status: 'error',
          variant: 'subtle',
          position: 'top',
          isClosable: true,
        });
      }
    }

    return;
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={8}
        mx={'auto'}
        maxW={{ base: '450px', md: '450px', lg: '480px' }}
        minW={{ base: '384px', md: '450px', lg: '480px' }}
        py={{ base: 6, lg: 6 }}
        px={{ base: 4, lg: 6 }}
      >
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            {t('signUpPage.header')}
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={{ base: 6, lg: 8 }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl
                    id="phoneNumber"
                    isInvalid={Boolean(formik.errors.phoneNumber)}
                  >
                    <FormLabel>{t('signUpPage.phone')}</FormLabel>
                    <InputGroup>
                      {/* eslint-disable-next-line react/no-children-prop */}
                      <InputLeftAddon children="+996" />
                      <Field
                        as={Input}
                        name="phoneNumber"
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {t(`signUpPage.error.${formik.errors.phoneNumber}`)}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    id="email"
                    isInvalid={Boolean(formik.errors.email)}
                  >
                    <FormLabel>{t('signUpPage.email')}</FormLabel>
                    <Field as={Input} placeholder="Email адрес" name="email" />
                    <FormErrorMessage>
                      {t(`signUpPage.error.${formik.errors.email}`)}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    id="password"
                    isInvalid={Boolean(formik.errors.password)}
                  >
                    <FormLabel>{t('signUpPage.password')}</FormLabel>
                    <Field as={Input} type="password" name="password" />
                    <FormErrorMessage>
                      {t(`signUpPage.error.${formik.errors.password}`)}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="confirmPassword"
                    isInvalid={
                      Boolean(formik.errors.confirmPassword) &&
                      formik.touched.confirmPassword
                    }
                  >
                    <FormLabel>{t('signUpPage.confirmPassword')}</FormLabel>
                    <Field as={Input} type="password" name="confirmPassword" />
                    <FormErrorMessage>
                      {t(`signUpPage.error.${formik.errors.confirmPassword}`)}
                    </FormErrorMessage>
                  </FormControl>

                  <Stack spacing={10} pt={2}>
                    <Button
                      size="lg"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                      isLoading={isLoading}
                    >
                      {t('signUpPage.signup')}
                    </Button>
                  </Stack>
                  <Stack pt={1}>
                    <Text align={'center'} color="gray.500">
                      {t('signUpPage.alreadyAUser')}
                      <Link to="/login" style={{ color: '#4299e1' }}>
                        {' '}
                        {t('signUpPage.login')}
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};
