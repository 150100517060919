import { Box, Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  HiOutlineBriefcase,
  HiOutlineDocumentDuplicate,
  HiOutlineScale,
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IDocTypes {
  title: string;
  subTitle: string;
  icon: ReactNode;
  link: string;
}
function Feature(props: IDocTypes) {
  const { title, icon, subTitle, link } = props;

  return (
    <Link to={link}>
      <Box
        bg="#FBFCFF"
        height="162px"
        borderRadius="16px"
        border={'1px solid #E2E6EF'}
      >
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'#3778DB'}
          mb={1}
          paddingLeft={3}
          paddingTop={4}
        >
          {icon}
        </Flex>
        <Box paddingLeft={4}>
          <Text fontSize="md" fontWeight={'bold'}>
            {title}
          </Text>
          <Text color="gray.400" paddingTop={2}>
            {subTitle}
          </Text>
        </Box>
      </Box>
    </Link>
  );
}

export default function DocTypesCards() {
  const { t } = useTranslation();

  return (
    <Box paddingTop={10}>
      <SimpleGrid
        minChildWidth={{ base: '220px', lg: '140px', md: '140px' }}
        spacing="24px"
      >
        <Feature
          icon={<Icon as={HiOutlineBriefcase} w={10} h={10} />}
          title={t('homePage.documentTypes.employmentContracts')}
          subTitle={'15 документов'}
          link={'/docs?type=EMPLOYMENT_CONTRACTS'}
        />
        <Feature
          icon={<Icon as={HiOutlineDocumentDuplicate} w={10} h={10} />}
          title={t('homePage.documentTypes.businessContracts')}
          subTitle={'20 документов'}
          link={'/docs?type=BUSINESS_CONTRACTS'}
        />
        <Feature
          icon={<Icon as={HiOutlineScale} w={10} h={10} />}
          title={t('homePage.documentTypes.courtDocuments')}
          subTitle={'5 документов'}
          link={'/docs?type=COURT_DOCUMENTS'}
        />
      </SimpleGrid>
      <Flex align={'center'} justify={'center'} paddingTop={6}>
        <Link to="/docs?type=BUSINESS_CONTRACTS">
          <Text color="blue.600">{t('homePage.allDocuments')}</Text>
        </Link>
      </Flex>
    </Box>
  );
}
