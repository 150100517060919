import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { Navbar } from '../Navbar';

export const UnauthorizedRoutes = () => {
  return (
    <Box>
      <Navbar />
      <Outlet />
    </Box>
  );
};
