import { ChangeEvent, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { useResetPhoneNumberMutation } from '../../features/auth/authApiSlice';

export interface IPhoneNumberModal {
  isOpen: boolean;
  // onOpen: () => void;
  onClose: () => void;
}

export const PhoneNumberModal = ({ onClose, isOpen }: IPhoneNumberModal) => {
  const [value, setValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [resetPhoneNumber, { isLoading, isSuccess }] =
    useResetPhoneNumberMutation();
  const { t } = useTranslation();
  const toast = useToast();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    const newValue = e.currentTarget.value;
    setValue(newValue);
  };

  const handleSubmit = async () => {
    const isValidNumber = /^\d{9}$/.test(value);

    if (!isValidNumber) {
      setIsInvalid(true);
      return;
    }

    try {
      await resetPhoneNumber({ phoneNumber: value }).unwrap();
      if (isSuccess) {
        toast({
          title: t('verifyOtpPage.phoneNumberChangedSuccessfully'),
          status: 'success',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });

        onClose();
        setValue('');
        setIsInvalid(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        setIsInvalid(true);
        toast({
          title: t('verifyOtpPage.error.phoneNumberExistError'),
          status: 'error',
          variant: 'top-accent',
          position: 'top',
          isClosable: true,
        });
      }
    }
  };

  const handleCloseModal = () => {
    onClose();
    setValue('');
    setIsInvalid(false);
  };

  return (
    <Modal onClose={handleCloseModal} size={'2xl'} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Впишите ваш номер телефона</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl id="phoneNumber" isInvalid={isInvalid}>
              <InputGroup>
                <InputLeftAddon>+996</InputLeftAddon>
                <Input
                  name="phoneNumber"
                  value={value}
                  onChange={handleChange}
                />
              </InputGroup>
              <FormErrorMessage>
                {t(`verifyOtpPage.error.invalidPhoneNumber`)}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleCloseModal} variant="ghost" mr={3}>
            Закрыть
          </Button>
          <Button
            variant="ghost"
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Сохранить
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
