import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  Text,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';

export const PrivacyPolicy = () => {
  const generateDate = () => {
    const date = new Date();
    return `По состоянию на ${format(date, 'dd.MM.yyyy')}`;
  };

  return (
    <>
      <Container maxW={'7xl'} minH={'90vh'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          py={6}
          px={4}
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={'/'}>
              Главная
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true}>
            <BreadcrumbLink
              style={{ cursor: 'default', textDecoration: 'none' }}
            >
              Политика конфиденциальности
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box pt={'4'}>
          <Card p={'20px'}>
            <CardHeader>
              <Heading size="md">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</Heading>
            </CardHeader>
            <CardBody>
              <Stack spacing={6}>
                <Box>
                  <Heading
                    as="h4"
                    fontSize="14px"
                    textAlign="right"
                    width="100%"
                  >
                    {generateDate()}
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm" textAlign="center">
                      Настоящая Политика конфиденциальности персональных данных
                      (далее – «Политика конфиденциальности») действует в
                      отношении всей информации, который Сайт
                      «https://legaldocs.kg/», может получить о Пользователе
                      (Клиенте) во время использования.
                    </Text>
                  </Box>
                </Box>

                <Box>
                  <Heading as="h4" size="sm">
                    1. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      1.1. В настоящей Политике конфиденциальности используются
                      следующие значения: <b>Администрация Сайта</b> – Общество
                      с ограниченной ответственностью «В рамках закона»,
                      зарегистрированное в Кыргызской Республике, создавшее
                      Сайт, осуществляющее администрирование и техническую
                      поддержку его работы, и предоставляющее Пользователям
                      услуги посредством данного Сайта.
                    </Text>
                    <Text fontSize="sm">
                      <b>Сайт</b> – это специализированное аппаратно-программное
                      обеспечение, созданное и функционирующее как
                      онлайн-сервис, составляющий шаблоны правовых документов на
                      основании полученных ответов от Пользователя.
                    </Text>
                    <Text fontSize="sm">
                      <b>Персональные данные</b> – любая личная информация,
                      относящаяся к прямо или косвенно определенному, или
                      определяемому физическому лицу (субъекту персональных
                      данных)
                    </Text>
                    <Text fontSize="sm">
                      <b>Обработка персональных данных</b> – любое действие
                      (операция) или совокупность действий (операций),
                      совершаемых с использованием средств автоматизации или без
                      использования таких средств с персональными данными,
                      включая сбор, запись, систематизацию, накопление,
                      хранение, уточнение (обновление, изменение), извлечение,
                      использование, передачу (распространение, предоставление,
                      доступ), обезличивание, блокирование, удаление,
                      уничтожение персональных данных.
                    </Text>
                    <Text fontSize="sm">
                      <b>Конфиденциальность персональных данных</b> –
                      обязательное для соблюдения Администрацией Сайта
                      требование не допускать распространение персональных
                      данных без согласия субъекта персональных данных или
                      наличия иного законного основания.
                    </Text>
                    <Text fontSize="sm">
                      <b>Пользователь</b> – физическое лицо старше 18 лет или
                      юридическое лицо, принявшее условия Пользовательского
                      соглашения и иных правил и политик ОсОО «В рамках закона»
                      и имеющий правомерный доступ к Сайту посредством сети
                      Интернет.
                    </Text>
                    <Text fontSize="sm">
                      <b>Cookies</b> — небольшой фрагмент данных, отправленный
                      веб-сервером и хранимый на компьютере пользователя,
                      который веб-клиент или веб-браузер каждый раз пересылает
                      веб-серверу в HTTP-запросе при попытке открыть страницу
                      соответствующего сайта. Сookie-файлы могут быть как
                      «сеансовые», так и «постоянные».
                    </Text>
                    <Text fontSize="sm">
                      <b>Сеансовые cookie-файлы</b> – Администрация Сайта вправе
                      использовать сеансовые cookie-файлы, чтобы присвоить
                      уникальный идентификационный номер компьютеру Пользователя
                      при каждом посещении Сайта, при этом после закрытия
                      браузера они удаляются. Такие файлы применяются также для
                      анализа работы Пользователя с Сайтом (изучается посещение
                      страниц, используемых ссылок и время пребывания
                      пользователя на той или иной странице).
                    </Text>
                    <Text fontSize="sm">
                      <b>Постоянные cookie-файлы</b> – Веб-сервер Администрации
                      Сайта может распознавать постоянные cookie, которые
                      хранятся на жестких дисках компьютеров пользователей, и,
                      присваивая устройствам пользователей уникальные
                      идентификаторы, Администрация Сайта может создать базу
                      данных о действиях и предпочтениях посетителей Сайта (в
                      частности, о частоте посещения и периодичности возвращения
                      Пользователей, об их предпочтениях на Сайте, а также
                      эффективности мероприятий компании по продвижению).
                    </Text>
                    <Text fontSize="sm">
                      <b>IP-адрес</b> — уникальный сетевой адрес узла в
                      компьютерной сети, построенной по протоколу IP.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">2. ОБЩИЕ ПОЛОЖЕНИЯ</Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      2.1. Использование Пользователем услуг Сайта означает его
                      согласие с настоящей Политикой конфиденциальности и
                      условиями обработки персональных данных Пользователя.
                    </Text>
                    <Text fontSize="sm">
                      2.2. В случае несогласия с условиями Политики
                      конфиденциальности Пользователь должен прекратить
                      использование услуг, материалов и сервисов Сайта.
                    </Text>
                    <Text fontSize="sm">
                      2.3. Настоящая Политика конфиденциальности применяется
                      только по отношению к Сайту. Администрация Сайта не
                      контролирует и не несет ответственности за веб-сайты
                      третьих лиц, на которые Пользователь может перейти по
                      ссылкам, доступным на Сайте. Администрация призывает
                      Пользователя перед представлением своей персональной
                      информации на сторонних веб-сайтах внимательно
                      ознакомиться с их политиками конфиденциальности.
                    </Text>
                    <Text fontSize="sm">
                      2.4. Пользователь заверяет, что вводимые им персональные
                      данные достоверны и не нарушают законные прав третьих лиц.
                    </Text>
                    <Text fontSize="sm">
                      2.5. Администрация не проверяет достоверность персональных
                      данных, предоставляемых Пользователем.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">
                    3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      3.1. Настоящая Политика конфиденциальности устанавливает
                      обязательства Администрации Сайта по неразглашению и
                      обеспечению режима защиты конфиденциальности персональных
                      данных, которые Пользователь предоставляет при
                      использовании Сайта.
                    </Text>
                    <Text fontSize="sm">
                      3.2. Администрация Сайта предпринимает соответствующие
                      меры для обеспечения безопасности персональной информации
                      Пользователя. Однако метод передачи информации и метод ее
                      хранения в сети Интернет не может быть полностью
                      безопасным, поэтому Администрация Сайта не гарантирует
                      абсолютную безопасность персональной информации.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">
                    4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      4.1. Администрация Сайта может использовать персональные
                      данные исключительно в целях оформления оказания услуг, а
                      также:
                    </Text>
                    <Text fontSize="sm">
                      4.1.1. установления с Пользователем обратной связи;
                    </Text>
                    <Text fontSize="sm">
                      4.1.2. подтверждения (в случае необходимости)
                      достоверности и полноты персональных данных,
                      предоставленных Пользователем;
                    </Text>
                    <Text fontSize="sm">
                      4.1.3. определения места нахождения Пользователя в целях,
                      определенных Пользовательским соглашением;
                    </Text>
                    <Text fontSize="sm">
                      4.1.4. обработки и получения платежей;
                    </Text>
                    <Text fontSize="sm">
                      4.1.5. осуществления рекламной деятельности, деятельности
                      по информированию Пользователя об акциях Сайта, но с
                      согласия Пользователя;
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">
                    5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                  </Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      5.1. Обработка персональных данных Пользователя
                      осуществляется без ограничения срока любым законным
                      способом, в том числе в информационных системах
                      персональных данных с использованием средств автоматизации
                      или без использования таких средств.
                    </Text>
                    <Text fontSize="sm">
                      5.2. Пользователь соглашается с тем, что Администрация
                      Сайта вправе передавать персональные данные третьим лицам,
                      в частности, кадровым агентствам, работодателям,
                      операторам электросвязи, курьерским службам, исключительно
                      в целях оказания услуг Пользователю Сайта.
                    </Text>
                    <Text fontSize="sm">
                      5.3. Персональные данные Пользователя могут быть переданы
                      уполномоченным государственным органам по основаниям и в
                      порядке, предусмотренным законодательством Кыргызской
                      Республики.
                    </Text>
                    <Text fontSize="sm">
                      5.4. При утрате или разглашении персональных данных
                      Администрация Сайта информирует Пользователя о
                      случившемся.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">6. ОБЯЗАТЕЛЬСТВА СТОРОН</Heading>
                  <Box pt={4}>
                    <Text fontSize="sm" as="b">
                      6.1. Пользователь обязан:
                    </Text>
                    <Text fontSize="sm">
                      6.1.1. предоставить достоверную и полную информацию о
                      персональных данных, необходимую для оформления заказа;
                    </Text>
                    <Text fontSize="sm">
                      6.1.2. по запросу Администрации Сайта предоставит
                      необходимую дополнительную информацию;
                    </Text>
                    <Text fontSize="sm">
                      6.1.3. соблюдать условия Пользовательского соглашения
                      Сайта, а также действующей Политики конфиденциальности;
                    </Text>
                    <Text fontSize="sm" as="b">
                      6.2. Администрация Сайта обязана:
                    </Text>
                    <Text fontSize="sm">
                      6.2.1. использовать полученную информацию исключительно в
                      целях качественного предоставления услуг Пользователям;
                    </Text>
                    <Text fontSize="sm">
                      6.2.2. обеспечить по мере своей возможности хранение
                      конфиденциальной информации;
                    </Text>
                    <Text fontSize="sm">
                      6.2.3. отказаться от оказания услуг Пользователю в случае
                      неуплаты последним стоимости услуг, либо предоставления
                      ложных сведений о персональных данных, которое может
                      затруднить оказание услуг.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">7. ОТВЕТСТВЕННОСТЬ СТОРОН</Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      7.1. Администрация, не исполнившая свои обязательства,
                      несёт ответственность в соответствии с законодательством
                      Кыргызской Республики.
                    </Text>
                    <Text fontSize="sm">
                      7.2. В случае утраты или разглашения конфиденциальной
                      информации Администрация Сайта не несёт ответственность,
                      если данная конфиденциальная информация:
                    </Text>
                    <Text fontSize="sm">
                      7.2.1. стала носить публичный характер до её утраты или
                      разглашения;
                    </Text>
                    <Text fontSize="sm">
                      7.2.2. относится к общедоступному массиву персональных
                      данных;
                    </Text>
                    <Text fontSize="sm">
                      7.2.3. предоставлена на основании соответствующего запроса
                      уполномоченного государственного органа;
                    </Text>
                    <Text fontSize="sm">
                      7.2.4. получена третьей стороной до момента её получения
                      Администрацией Сайта;
                    </Text>
                    <Text fontSize="sm">
                      7.2.5. разглашена с согласия Пользователя.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">8. РАЗРЕШЕНИЕ СПОРОВ</Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      8.1. Все споры, возникающие из отношений между
                      Пользователем Сайта и Администрацией Сайта, будут
                      разрешаться путем переговоров и подготовки претензионных
                      писем.
                    </Text>
                    <Text fontSize="sm">
                      8.2. Если Стороны не достигнут соглашения в ходе
                      переговоров, или на полученное претензионное письмо не
                      будет получен ответ в течение 15 (пятнадцати) календарных
                      дней, то спор подлежит рассмотрению в порядке,
                      предусмотренном законодательством Кыргызской Республики.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading size="md">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</Heading>
                  <Box pt={4}>
                    <Text fontSize="sm">
                      9.1. Администрация вправе вносить изменения в настоящую
                      Политику конфиденциальности без согласия Пользователя.
                    </Text>
                    <Text fontSize="sm">
                      9.2. Новая Политика конфиденциальности вступает в силу с
                      момента ее размещения на Сайте, если иное не предусмотрено
                      новой редакцией Политики конфиденциальности.
                    </Text>
                    <Text fontSize="sm">
                      9.3. При дальнейшем пользовании услугами Сайта,
                      Пользователь соглашается с новыми условиями Политики
                      конфиденциальности. В случае несогласия Пользователя с
                      новыми условиями Политики конфиденциальности Пользователь
                      обязан покинуть Сайт и не использовать в дальнейшем данный
                      Сайт.
                    </Text>
                  </Box>
                </Box>
                <Box>
                  {' '}
                  <Text fontSize="sm" as="b" textAlign="center">
                    Пользователь подтверждает, что ознакомлен со всеми пунктами
                    настоящей Политики конфиденциальности и безусловно принимает
                    их.
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      </Container>
    </>
  );
};
