import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react';
import React from 'react';

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  isActive?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, isActive, ...buttonProps } = props;
  return (
    <Button
      variant="ghost"
      colorScheme={isActive ? 'blue' : 'gray'}
      justifyContent="start"
      color={'gray.600'}
      {...buttonProps}
      isActive={isActive}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" color="subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
