import React from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  Highlight,
  HStack,
  Icon,
  Img,
  Image,
  Link,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { HiChevronRight } from 'react-icons/hi';

import { NavbarTransparent } from '../components/common/NavbarTransparent';
import { Testimonial } from '../components/AboutUs/Testimonial';

import heroImg from '../assets/about-us/about_us_hero_img.svg';
import fatima from '../assets/about-us/fatima.jpeg';
import zulia from '../assets/about-us/zulia.jpeg';
import shirin from '../assets/about-us/shirin.jpeg';

import { IPartner, Partners } from '../constants/partners';

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavbarTransparent />
      <Flex
        as="section"
        minH={{ base: '30vh', lg: '60vh' }}
        bgColor="#0d1074"
        justifyContent="space-evenly"
      >
        <Show breakpoint="(min-width: 600px)">
          <Box pt={{ base: 10, lg: 24 }}>
            <Image
              src={heroImg}
              alt="Hero image"
              h={{ base: 'md', lg: 'sm', sm: 'lg' }}
            />
          </Box>
        </Show>
        <Box py={{ base: 10, lg: 24 }} zIndex={1}>
          <Box
            px={{ base: 8, md: '8' }}
            color="white"
            textAlign={{ base: 'center', md: 'inherit' }}
          >
            <Box maxW="xl">
              <Heading
                as="h1"
                size={{ base: '2xl', md: '3xl' }}
                fontWeight="extrabold"
              >
                Защищаем права – в рамках закона
              </Heading>
              <Text fontSize={{ md: '2xl' }} mt="4" maxW="lg">
                Документы, составленные опытными юристами, гарантируют вашу
                защиту и успех.
              </Text>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                mt="24"
                spacing="4"
              >
                <HStack
                  as="a"
                  transition="background 0.2s"
                  justify={{ base: 'center', lg: 'flex-end' }}
                  href="/docs?type=BUSINESS_CONTRACTS"
                  color="white"
                  rounded="full"
                  fontWeight="bold"
                  px="6"
                  py="3"
                  bg="whiteAlpha.300"
                  _hover={{ bg: 'whiteAlpha.900', color: '#0d1074' }}
                >
                  <span>Создать документ</span>
                  <HiChevronRight />
                </HStack>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box
        m="0 auto"
        as="section"
        maxW="7xl"
        py={{ base: 5, md: 20 }}
        px={4}
        textAlign={{ base: 'center', md: 'left' }}
      >
        <Heading
          as="h2"
          size={{ base: 'xl', md: '2xl' }}
          color="gray.700"
          pb={16}
        >
          Кто мы такие?
        </Heading>
        <Text pb={4} fontSize={{ base: 'lg', md: 'xl' }} color="muted">
          <Highlight
            query={['«В рамках закона»']}
            styles={{ px: '1', bg: 'blue.100' }}
          >
            Команда «В рамках закона» была образована в период октябрьских
            волнений в 2020 году. Тогда инициативные юристы объединившись начали
            работу над информационным и правовым просвещением населения.
          </Highlight>
        </Text>
        <Text pb={4} fontSize={{ base: 'lg', md: 'xl' }} color="muted">
          Мы стали выпускать правовые материалы/дорожные карты о том, как
          государственные органы должны действовать в непростых для страны
          обстоятельствах.
        </Text>
        <Text pb={4} fontSize={{ base: 'lg', md: 'xl' }} color="muted">
          Через год просветительской деятельности было решено создать
          юридическую фирму.
        </Text>
        <Text pb={4} fontSize={{ base: 'lg', md: 'xl' }} color="muted">
          В настоящее время, компания занимается юридическим и бухгалтерским
          сопровождением клиентов (IT- компании, образовательные центры и НКО),
          правовым просвещением (подкасты, эфиры, публикации в соцсетях и тд).
        </Text>
        <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
          <Highlight
            query={['legal tech']}
            styles={{ px: '1', bg: 'blue.100' }}
          >
            Кроме этого, мы продвигаем legal tech движение в Кыргызстане,
            запускаем проекты которые оптимизируют работу юристов!
          </Highlight>
        </Text>
      </Box>
      <Box
        as="section"
        bg="bg-surface"
        m="0 auto"
        maxW="7xl"
        textAlign={{ base: 'center', md: 'left' }}
      >
        <Heading
          as="h2"
          size={{ base: 'xl', md: '2xl' }}
          color="gray.700"
          pb={{ base: 5, md: 20 }}
        >
          О юристах
        </Heading>
        <Box position="relative" height={{ lg: '720px' }}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '16' }}
            height="full"
            px={4}
          >
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing="4">
                <Stack
                  spacing={{ base: '4', md: '6' }}
                  maxW={{ md: 'xl', lg: 'md', xl: 'xl' }}
                >
                  <Heading size={useBreakpointValue({ base: 'md', md: 'lg' })}>
                    Фатима Якупбаева
                  </Heading>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['«В рамках закона»']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Адвокат и управляющий партнер фирмы «В рамках закона».
                      Фатима обладает более 12-летним опытом работы в
                      юридическом бизнесе.
                    </Highlight>
                  </Text>

                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['более 1 000 различных договоров']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Составила более 1 000 различных договоров для клиентов из
                      бизнес, некоммерческого и государственного секторов. Более
                      100 выигранных судебных и внесудебных споров. Защищала
                      интересы предпринимателей, инвесторов, журналистов,
                      творческих деятелей как на местном, так и международном
                      уровне.
                    </Highlight>
                  </Text>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    Арбитр, медиатор и заместитель председателя по медиации в
                    Международном Третейском суде при Торгово-Промышленной
                    палате КР.
                    <br />
                    Член Ассоциации юристов Кыргызстана, постоянный лектор в
                    Палате налоговых консультантов.
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Box
              pos={{ lg: 'absolute' }}
              right="0"
              bottom="0"
              w={{ base: 'full', lg: '50%' }}
              height={{ base: '96', lg: 'full' }}
              sx={{
                clipPath: {
                  lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
                },
              }}
            >
              <Img
                boxSize="full"
                objectFit="cover"
                src={fatima}
                alt="Fatima Iakupbaeva"
              />
            </Box>
          </Stack>
        </Box>

        <Box
          position="relative"
          height={{ lg: '720px' }}
          mt={{ base: 12, md: 36 }}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '16' }}
            height="full"
            px={4}
          >
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing="4">
                <Stack
                  spacing={{ base: '4', md: '6' }}
                  maxW={{ md: 'xl', lg: 'md', xl: 'xl' }}
                >
                  <Heading size={useBreakpointValue({ base: 'md', md: 'lg' })}>
                    Ширин Каныбекова
                  </Heading>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['«В рамках закона»']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Старший юрист фирмы «В рамках закона» с более 9-летним
                      опытом работе в сфере корпоративного и договорного права.
                    </Highlight>
                  </Text>

                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['«Токтом»', 'legal tech']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Ширин много лет сопровождала крупнейшие сделки в сфере
                      туризма, деловой недвижимости и инвестиций.
                    </Highlight>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Box
              pos={{ lg: 'absolute' }}
              right="0"
              bottom="0"
              w={{ base: 'full', lg: '50%' }}
              height={{ base: '96', lg: 'full' }}
              sx={{
                clipPath: {
                  lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
                },
              }}
            >
              <Img
                boxSize="full"
                objectFit="cover"
                src={shirin}
                alt="Shirin Kanybekova"
              />
            </Box>
          </Stack>
        </Box>

        <Box
          position="relative"
          height={{ lg: '720px' }}
          mt={{ base: 12, md: 36 }}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '16' }}
            height="full"
            px={4}
          >
            <Stack spacing={{ base: '8', md: '12' }}>
              <Stack spacing="4">
                <Stack
                  spacing={{ base: '4', md: '6' }}
                  maxW={{ md: 'xl', lg: 'md', xl: 'xl' }}
                >
                  <Heading size={useBreakpointValue({ base: 'md', md: 'lg' })}>
                    Зулия Нурбекова
                  </Heading>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['«В рамках закона»']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Юрист фирмы «В рамках закона» с более 7-летним опытом
                      работе в сфере трудового, корпоративного и гражданского
                      права.
                    </Highlight>
                  </Text>

                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    <Highlight
                      query={['«Токтом»', 'legal tech']}
                      styles={{ px: '1', bg: 'blue.100' }}
                    >
                      Зулия более 5 лет проработала юристом в Информационном
                      центре «Токтом» - это первый legal tech в Кыргызстане,
                      создавший удобный сервис поиска нормативных актов.
                    </Highlight>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Box
              pos={{ lg: 'absolute' }}
              right="0"
              bottom="0"
              w={{ base: 'full', lg: '50%' }}
              height={{ base: '96', lg: 'full' }}
              sx={{
                clipPath: {
                  lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
                },
              }}
            >
              <Img
                boxSize="full"
                objectFit="cover"
                src={zulia}
                alt="Zulia Nurbekova"
              />
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box as="section" bg="gray.50">
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Box pt={{ base: 12 }} pb={{ base: 5, md: 10 }}>
            <Heading as="h2" size={{ base: 'xl', md: '2xl' }} color="gray.700">
              Вы в надежных руках
            </Heading>
            <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.600">
              Нам доверяют десятки ведущих компаний Кыргызстана!
            </Text>
          </Box>
          <SimpleGrid
            py="16"
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: '16', lg: '32' }}
          >
            {Partners.map((partner: IPartner) => (
              <Testimonial
                key={partner.name}
                name={partner.name}
                role={partner.role}
                image={partner.image}
                logo={partner.logo}
                link={partner.link}
              >
                {partner.quote}
              </Testimonial>
            ))}
          </SimpleGrid>
        </Box>
      </Box>

      <Box bg={'#172334'} color={'white'}>
        <Container as={Stack} maxW={'7xl'} py={10}>
          <SimpleGrid
            templateColumns={{ sm: '2fr 2fr', md: '2fr 2fr 2fr 2fr' }}
            spacing={16}
          >
            <Stack spacing={2}>
              <Box>
                <Text fontSize={'24px'} fontWeight={600}>
                  Legal Docs
                </Text>
              </Box>
              <Box>
                <Text fontSize={'sm'}>+996990337700</Text>
                <Text fontSize={'sm'}>+996703932001</Text>
                <Text fontSize={'sm'}>legaldocs.kg@gmail.com</Text>
              </Box>

              <HStack align={'flex-start'}>
                <Link
                  href="https://www.instagram.com/v_ramkah_z/"
                  target="_blank"
                >
                  <Icon as={AiFillInstagram} h={'25px'} w={'25px'} />
                </Link>
                <Link
                  href="https://www.facebook.com/vramkahzakona"
                  target="_blank"
                >
                  <Icon as={AiFillFacebook} h={'25px'} w={'25px'} />
                </Link>
                <Link
                  href="https://www.youtube.com/channel/UCj3-VwHTfn8r4zjo-J2qbdA"
                  target="_blank"
                >
                  <Icon as={AiFillYoutube} h={'25px'} w={'25px'} />
                </Link>
              </HStack>
              <Box pt={2}>
                <Text fontSize={'sm'}>
                  © {new Date().getFullYear()} {t('common.allRightsReserved')}
                </Text>
              </Box>
            </Stack>

            <Stack align={'flex-start'}>
              <Link href={'/user-agreement'}>{t('common.termsOfUse')}</Link>
              <Link href={'/privacy-policy'}>{t('common.privacyPolicy')}</Link>
              <Link href={'/payment-terms'}>{t('common.termsOfPayment')}</Link>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
};
